import React, { useState, useEffect, useMemo, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import {
    Badge,
    Col,
    Row,
    Table,
} from "reactstrap";
import { GetAccessTokenSilentlyOptions, PaginatedAPIResponse, Order, EpicPenContext } from "../../epicPenHelpers/epicpen_licencing_api";
import { buildInfo } from "../../epicPenHelpers/build_info";
import { countryCodeToName, moneyToDisplayString } from "../../epicPenHelpers/epicPenUtils";
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from "./../LoadingSpinner";
import { data } from "jquery";
import { PaginatedTable, TableContainer } from "./TableContainer";



interface OrderTableProps {
    minimal?: boolean,
    currentUserOnly: boolean
    subscription2id?: string
    userId?: string
}

const OrderTable = (props: OrderTableProps) => {
    const { api, locale } = useContext(EpicPenContext);
    return PaginatedTable<Order>({
        minimal: props.minimal,
        fetchData: async (pageIndex, pageSize, searchString, abortController) => await api.getOrders( pageIndex, pageSize, searchString, props.currentUserOnly, props.subscription2id, props.userId, abortController),
        columns: [
            {
                Header: "Order Date",
                accessor: "orderDate",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    return `${new Date(cellProps.value).toLocaleTimeString(locale)} ${new Date(cellProps.value).toLocaleDateString(locale)}`;
                },
            },
            {
                Header: "Amount",
                accessor: "moneyAmount",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    return <React.Fragment>{moneyToDisplayString(cellProps.value ?? 0, cellProps.row.original.currency)} {cellProps.row.original.status === "refunded" ? <Badge className="bg-danger">Refunded</Badge> : []}</React.Fragment>;
                },
            },
            {
                Header: "Payout amount",
                accessor: "payoutMoneyAmount",
                adminOnly: true,
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.row.original.payoutCurrency ? moneyToDisplayString(cellProps.value ?? 0, cellProps.row.original.payoutCurrency) : "";
                },
            },
            {
                Header: "Type",
                accessor: "mode",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value === "subscription" ? "Subscription" : "Once off";
                },
            },
            {
                Header: "User",
                accessor: "user.email",
                includeInMinimal: false,
                adminOnly: true,
                Cell: (cellProps: any) => {
                    return cellProps.row.original.userId && cellProps.value ? <Link to={`/profile/${cellProps.row.original.userId}`}>{cellProps.value}</Link> : [];
                },
            },
            {
                Header: "Country",
                accessor: "user.countryCode",
                includeInMinimal: false,
                adminOnly: true,
                Cell: (cellProps: any) => {
                    return countryCodeToName(cellProps.value);
                },
            },
            {
                Header: "",
                accessor: "action",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    //console.log(JSON.stringify(cellProps));
                    if (cellProps.row.fastSpringOrderRef) {
                        return <a href={`https://licencing.epicpen.com/downloadOrderInvoice?orderId=${cellProps.row.original.id}`} target="_blank" rel="noreferrer"><button
                            type="button"
                            className="btn btn-light btn-sm"
                        >
                            Download invoice
                        </button></a>
                    } else if (cellProps.row.paddleReceiptURL) {
                        return <a href={cellProps.row.paddleReceiptURL} target="_blank" rel="noreferrer"><button
                            type="button"
                            className="btn btn-light btn-sm "
                        >
                            View receipt
                        </button></a>
                    } else {
                        return [];
                    }

                },
            },
            {
                Header: "",
                accessor: "action2",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    //console.log(JSON.stringify(cellProps));
                    return <Link to={`/orders/${cellProps.row.original.id}`}><button
                        type="button"
                        className="btn btn-light btn-sm float-end"
                    >
                        View details
                    </button></Link>;
                },
            }
        ]
    })
}

export default OrderTable;
