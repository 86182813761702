import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { buildInfo } from "../../epicPenHelpers/build_info";

interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  path?: string;
  exact?: boolean;
  key?: number;
}

const Authmiddleware = ({
  component,
  layout,
  isAuthProtected,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const Layout = layout;
  const Component = component;

  return (
    <Route
      {...rest}
      render={props => {
        //if (isAuthProtected && !localStorage.getItem("authUser")) {
        //  return (
        //    <Redirect
        //      to={{ pathname: "/login", state: { from: props.location } }}
        //    />
        //  );
        //}

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};
const params : any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop  : any) => searchParams.get(prop),
});
let login_hint = params.login_hint; // "some_value"
console.log(`login_hint: ${login_hint}`)
export default buildInfo.isDevMachine ? Authmiddleware : withAuthenticationRequired(Authmiddleware, login_hint ? { loginOptions: { login_hint: login_hint } } : undefined);
