import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
    Col,
    Row,
    Table,
} from "reactstrap";


const LoadingSpinner = (props: any) => {
    return <Row className="icon-demo-content">
            <Col xl={12} lg={12} sm={12}>
                <i className="dripicons-loading mdi-spin"></i> Loading
            </Col>
        </Row>
}

export default LoadingSpinner;
