import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem, Modal, Input, Alert, CardHeader, CardBody, CardFooter, CardGroup } from "reactstrap";
import { GetAccessTokenSilentlyOptions, BillingPeriod, DetailedSubscription2, PaddleBillingType, PaddleSubscriptionPlan, Subscription2, EpicPenContext } from "../epicPenHelpers/epicpen_licencing_api";
import { buildInfo } from "../epicPenHelpers/build_info";
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from "react-ga4";
import LoadingSpinner from "./LoadingSpinner";
import { activationCodeToStatusBadge, moneyToDisplayString, nameof } from "../epicPenHelpers/epicPenUtils";
import EPModal from "./EPModal";

interface SubscriptionCardContentProps {
    activationCodeId: string
    setNewNumOfLicences : (numOfLicences : number) => void
}

interface AlertProps {
    danger: boolean
    message: string
}

const SubscriptionCardContent = (props: SubscriptionCardContentProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [updatingSubscription, setUpdatingSubscription] = useState<boolean>(false);
    const [subscription, setSubscription] = useState<DetailedSubscription2 | null>(null);
    const [modal_update_subscription, setmodal_update_subscription] = useState(false);


    const [cancelationState, setCancelationState] = useState<{ state : "idle"} | { state : "showModal", cancelationInProgress : boolean} | { state : "showResultModal", success : boolean, message : string}>({ state : "idle"});

    const Paddle = (window as any).Paddle;

    const [alert, setAlert] = useState<AlertProps | null>(null);

    const [newBillingPeriod, setNewBillingPeriod] = useState<BillingPeriod | null>(null);
    const [newNumOfLicences, setNewNumOfLicences] = useState<number | null>(null);

    const { api } = useContext(EpicPenContext);


    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    }
    const tog_update_subscription = () => {
        if (!modal_update_subscription) {
            setAlert(null);
            setNewBillingPeriod(subscription?.subscription.billingPeriod!)
            setNewNumOfLicences(subscription?.usingPaddlingBilling ? subscription?.paddleBillingSubcription.items[0].quantity! :  subscription?.paddleSubscription.quantity!)
        }
        setmodal_update_subscription(!modal_update_subscription);
        removeBodyCss();
    }



    useEffect(() => {
        const getDeviceIds = async () => {
            try {
                const subscriptionTemp = await api.getDetailedSubscription2(props.activationCodeId);
                setSubscription(() => subscriptionTemp);
                setNewBillingPeriod(subscriptionTemp?.subscription.billingPeriod!)
                setNewNumOfLicences(subscriptionTemp.usingPaddlingBilling ? subscriptionTemp?.paddleBillingSubcription.items[0].quantity! :  subscriptionTemp?.paddleSubscription.quantity!)
                setLoading(false)
            } catch (e: any) {
                console.log(`call api error: ${JSON.stringify(e)}`);
            }
        };
        getDeviceIds();
    }, []);

    const paddleBilling: PaddleBillingType | null = (subscription?.subscription.billingPeriod === "monthly" ? "month" : (subscription?.subscription.billingPeriod === "yearly" ? "year" : null));
    const newPaddleBilling: PaddleBillingType | null = newBillingPeriod ? (newBillingPeriod === "monthly" ? "month" : (newBillingPeriod === "yearly" ? "year" : null)) : null;



    var newPlan: PaddleSubscriptionPlan | null = null;
    var plan: PaddleSubscriptionPlan | null = null;
    if (subscription && newPaddleBilling && !subscription.usingPaddlingBilling) {
        newPlan = subscription.paddleSubscriptionPlans.filter((plan) => {
            return plan.billing_type === newPaddleBilling
        })[0];


        plan = subscription.paddleSubscriptionPlans.filter((plan) => {
            return plan.billing_type === paddleBilling
        })[0];
    }

    const perLicencePriceStr = plan?.recurring_price[subscription?.subscription.currency.toUpperCase()!];
    const perLicencePrice = perLicencePriceStr ? parseFloat(perLicencePriceStr) : null;
    const newPerLicencePriceStr = newPlan?.recurring_price[subscription?.subscription.currency.toUpperCase()!];
    const newPerLicencePrice = newPerLicencePriceStr ? parseFloat(newPerLicencePriceStr) : null;

    const billingTypeWasChanged = newBillingPeriod !== subscription?.subscription.billingPeriod;
    const altertModded = alert ?? (billingTypeWasChanged ? { danger: true, message: "Changing the payment period will mean you are billed immediately on the new payment plan." } : null)

    const updateSubscriptionClick = async () => {
        setAlert(null);
        setUpdatingSubscription(() => true);
        try {
            await api.patchSubscription(props.activationCodeId, { [nameof<Subscription2>("numOfLicences")]: newNumOfLicences!, [nameof<Subscription2>("billingPeriod")]: newBillingPeriod! });
            setAlert({ danger: false, message: "Subscription was successfully updated." })
            setSubscription((detailedSubscription) => {
                var newDetailedSubscription: DetailedSubscription2 = JSON.parse(JSON.stringify(detailedSubscription))
                if (newDetailedSubscription.usingPaddlingBilling) {
                    newDetailedSubscription.paddleBillingSubcription.items[0].quantity = newNumOfLicences!} else {
                    newDetailedSubscription.paddleSubscription.quantity = newNumOfLicences!
                }
                newDetailedSubscription.subscription.numOfLicences = newNumOfLicences!;;
                newDetailedSubscription.subscription.billingPeriod = newBillingPeriod!;
                return newDetailedSubscription;
            })
            props.setNewNumOfLicences(newNumOfLicences!);
            setUpdatingSubscription(() => false);
        } catch (e: any) {
            console.log(`call api error: ${JSON.stringify(e)}`);
            setAlert({ danger: true, message: `Subscription was not updated. Error: ${e.message ?? ""}` })
            setUpdatingSubscription(() => false);
        }
    }


    const isTrialMode = subscription?.subscription.status === "trialing";
    //const daysLeftInTrial = subscription?.paddleSubscription?.next_payment?.date ? Math.floor((Date.parse(subscription?.paddleSubscription?.next_payment?.date!) - (Date.now())) / 86400000) : null;

    const updatePaymentMethodClick = async () => {
        window.open(subscription?.usingPaddlingBilling ? subscription.paddleBillingSubcription.management_urls.update_payment_method : subscription?.paddleSubscription.update_url, '_blank');
    };
    const cancelSubscriptionClick = async () => {
        if (subscription?.usingPaddlingBilling) {
            setCancelationState({ state: "showModal", cancelationInProgress: false });
        } else {
            window.open(subscription?.paddleSubscription.cancel_url, '_blank');
        }
    };

    

    const tryCancelSubscription = async () => {
        setCancelationState({ state: "showModal", cancelationInProgress: true });
        try {
            const result = (await api.cancelSubscription(props.activationCodeId));

            setCancelationState({ state: "showResultModal", success: true, message: "Your subscription was successfully cancelled"});
        } catch (e) {
            setCancelationState({ state: "showResultModal", success: false, message: "There was an error in cancelling your subscription. Please contact support."});
        }
    };


    const subscriptionCancelled = subscription?.subscription.status === "deleted"

    const cancelationPending = Boolean(subscription?.subscription.cancelationScheduledDate)

    ////{altertModded.danger ? "danger" : ""}>
    return <React.Fragment>
        <CardHeader>
            <h4 className="card-title">Subscription</h4>
        </CardHeader>
        {
            loading ? <React.Fragment><CardBody><LoadingSpinner /></CardBody></React.Fragment> : <React.Fragment><CardBody>
                {subscriptionCancelled ? <Fragment>
                    <h5>Status</h5>
                    <div className="mb-3">
                        <p>{subscriptionCancelled ? "Cancelled" : subscription?.subscription.status}</p>
                    </div>
                </Fragment> : []
                }
                {!subscriptionCancelled ? <React.Fragment>
                    <h5>Next bill cost</h5>
                    <div className="mb-3">
                        <span className="display-4">{subscription?.usingPaddlingBilling ? subscription.previewLineItem.formatted_totals.total : moneyToDisplayString(subscription?.subscription.nextPaymentAmount ?? 0, subscription?.subscription?.currency ?? null)}</span>
                    </div>
                    <Row>
                        <Col sm={12} md={6}>
                            <h5>Billing period</h5>
                            <div className="mb-3 text-muted">
                                <span>{subscription?.subscription.billingPeriod === "monthly" ? "Monthly" : "Yearly"}</span>
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            {subscription?.subscription?.nextBillDate ? <Fragment>
                                <h5>Next bill date</h5>
                                <div className="mb-3 text-muted">
                                    <span>{new Date(Date.parse(subscription?.subscription?.nextBillDate)).toDateString()}</span>
                                </div>
                            </Fragment> : []
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} md={6}>
                            <h5>Number of licences</h5>
                            <div className="mb-3 text-muted">
                                <p>{subscription?.subscription.numOfLicences}</p>
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <h5>Status</h5>
                            <p>{subscription?.subscription ? activationCodeToStatusBadge(subscription?.subscription) : []}</p>
                        </Col>
                    </Row>
                    {(!isTrialMode) ? <div className="mb-3">
                        <button type="submit" onClick={() => {
                            tog_update_subscription();
                        }} className="btn btn-primary w-md"
                            data-toggle="modal"
                            data-target="#myModal">
                            Change subscription settings
                        </button>
                    </div> : []}
                    <h5>Payment method</h5>
                    {
                        !subscription?.usingPaddlingBilling && subscription?.paddleSubscription?.payment_information?.payment_method === "card" ?
                            <React.Fragment>
                                <h6 className="text-muted"><i className="mdi mdi-card-text me-1"></i>Card</h6>
                                <p className="text-muted">Exp {subscription?.paddleSubscription.payment_information.expiry_date} - {subscription?.paddleSubscription.payment_information.last_four_digits}</p>
                            </React.Fragment> : 
                            <p className="text-muted">{!subscription?.usingPaddlingBilling && subscription?.paddleSubscription?.payment_information?.payment_method === "paypal" ? <React.Fragment><i className="fab fa-paypal"></i> PayPal</React.Fragment> : ""}</p>
                    }
                    <div className="mt-4 mb-3">
                        <button type="button" className="btn btn-primary w-md" onClick={() => updatePaymentMethodClick()}>
                            Update payment method
                        </button>
                    </div>

                    <Modal
                        isOpen={modal_update_subscription}
                        toggle={() => {
                            tog_update_subscription();
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Update subscription
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_update_subscription(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <h5>Payment rate</h5>

                            <div className="mb-3">
                                <span className="display-6">{!subscription?.usingPaddlingBilling ? moneyToDisplayString(newPerLicencePrice! * newNumOfLicences!, (subscription?.paddleSubscription?.next_payment?.currency) ?? null) : subscription.previewLineItem.formatted_totals.total}</span>
                                <span> / {newBillingPeriod === "monthly" ? "Month" : "Year"}</span>
                            </div>
                            <h5>Payment period</h5>
                            <select value={newBillingPeriod!} onChange={(event: any) => { setNewBillingPeriod(() => ((event.target as any).value)) }} className="form-select mb-3">
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                            </select>
                            <h5>Number of licences</h5>
                            <div className="mb-3">
                                <Input
                                    type="number"
                                    className="form-control"
                                    name="numOfLicences"
                                    contentEditable="true"
                                    value={newNumOfLicences?.toString()}
                                    onChange={(event: any) => { setNewNumOfLicences(() => parseInt(((event.target as any).value))) }}
                                    max="10000"
                                    min="1"
                                />
                            </div>


                            {
                                altertModded ?
                                    <Alert color={altertModded!.danger ? "danger" : "success"}>
                                        {altertModded!.message}
                                    </Alert> : []
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_update_subscription();
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary" onClick={(s) => { s.preventDefault(); updateSubscriptionClick() }}>
                                {updatingSubscription ? "Updating subscription..." : "Update subscription"}
                            </button>

                        </div>
                    </Modal>
                    
                        <EPModal
                            title="Cancel subscription"
                            isOpen={cancelationState.state === "showModal"}
                            close={() => setCancelationState({ state: "idle"})}
                            button={{
                                text: cancelationState.state === "showModal" && cancelationState.cancelationInProgress ? "Cancelling subscription..." : "Cancel my subscription",
                                color: "red",
                                loading: cancelationState.state === "showModal" && cancelationState.cancelationInProgress,
                                onClick: () => tryCancelSubscription()
                            }}
                        >
                            <div className="modal-body">
                                <p>Cancelling your subscription will mean you lose access to Epic Pen Pro features.
                                </p>
                                <p>Are you sure you want to cancel your subscription?
                                </p>
                            </div>
                        </EPModal>

                        <EPModal
                            title={cancelationState.state === "showResultModal" && cancelationState.success ? "Success" : "Error"}
                            isOpen={cancelationState.state === "showResultModal"}
                            close={() => setCancelationState({ state: "idle"})}
                            button={{
                                text: "Okay",
                                color: "blue",
                                loading: false,
                                onClick: () => setCancelationState({ state: "idle"})
                            }}
                        >
                            <div className="modal-body">
                                <p>{cancelationState.state === "showResultModal" ? cancelationState.message : ""}
                                </p>
                            </div>
                        </EPModal>
                    
                    </React.Fragment>
                    : []}
            </CardBody>
                {!subscriptionCancelled && !cancelationPending ?
                    < CardFooter >
                        <button type="submit" className="btn btn-danger w-md  float-end" onClick={() => cancelSubscriptionClick()}>
                            Cancel subscription
                        </button>
                    </CardFooter> : []
                }
            </React.Fragment>
        }
    </React.Fragment >
}

export default SubscriptionCardContent;
