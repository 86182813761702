import React, { useState, useEffect, useMemo, useContext } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Progress,
    DropdownMenu
} from "reactstrap";

import { getInvoices as onGetInvoices } from "../../store/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";


import { Link, useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react';
import { buildInfo } from "../../epicPenHelpers/build_info";
import { LicenceDetails, GetAccessTokenSilentlyOptions, EpicPenContext } from "../../epicPenHelpers/epicpen_licencing_api";
import ActivationCodeTable from "../../epicPenComponents/tables/ActivationCodeTable";
import OrderTable from "../../epicPenComponents/tables/OrderTable";




const Dashboard = () => {
    document.title = "Dashboard | Epic Pen";

    const [isLoading, setLoading] = useState<boolean>(true);
    const [licenceDetails, setLicenceDetails] = useState<LicenceDetails | null>(null);

    const { api } = useContext(EpicPenContext);
    useEffect(() => {
        const getActivationCodes = async () => {
            try {
                const licenceDetails = await api.getLicenceDetailsOfCurrentUser()
                setLicenceDetails(() => licenceDetails);
                setLoading(false)
            } catch (e: any) {
                console.log(`call api error: ${JSON.stringify(e)}`);
            }
        };
        getActivationCodes();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="Dashboard" />
                    {
                        true ? <Row>
                            <Col lg="4">
                                <Card>
                                    <CardHeader>
                                        <h4 className="card-title">Welcome to your Epic Pen account</h4>
                                        <p className="card-title-desc">You can use this account to view and manage your Epic Pen Pro licenses.</p>
                                    </CardHeader>
                                    <CardBody>
                                        <p>From this account you can:</p>
                                        <ul>
                                            <li>Find your Epic Pen Pro activation codes</li>
                                            <li>Reset activation codes in order to move your licences to new machines.</li>
                                            <li>Change your account details</li>
                                        </ul>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card>
                                    <CardHeader>
                                        <h4 className="card-title">Licences</h4>
                                        <p className="card-title-desc">A list of all your current Epic Pen Pro activation codes.</p>
                                    </CardHeader>
                                    <CardBody>
                                        {
                                            isLoading ? "" : <React.Fragment>
                                                <p>You have <b>{licenceDetails!.totalDeviceLicences}</b> active Epic Pen Pro {licenceDetails!.totalDeviceLicences > 1 ? "licenses" : "license"} on your account. <b>{licenceDetails?.activatedDevices}</b> {licenceDetails!.activatedDevices > 1 ? "licenses are" : "license is"} currently activated.</p>

                                                <Progress value={(Math.min(licenceDetails!.activatedDevices, licenceDetails!.totalDeviceLicences) / licenceDetails!.totalDeviceLicences) * 100} color="brand"></Progress>

                                                <Link to="/activationcodes" className="has-arrow">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary waves-effect waves-light w-100 mt-3"
                                                    >
                                                        View activation codes
                                                    </button>
                                                </Link></React.Fragment>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card>
                                    <CardHeader>
                                        <h4 className="card-title">Help</h4>
                                        <p className="card-title-desc">A list of helpful links.</p>
                                    </CardHeader>
                                    <CardBody>
                                        <a href="https://epicpen.com/downloadclean" rel="noreferrer" target="_blank" >
                                            <button
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light w-100 mt-3"
                                            >
                                                Download Epic Pen installer
                                            </button>
                                        </a>
                                        <a href="https://epicpen.com/userguide" rel="noreferrer" target="_blank" >
                                            <button
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light w-100 mt-3"
                                            >
                                                User guide
                                            </button>
                                        </a>
                                        <a href="https://epicpen.com/faq" rel="noreferrer" target="_blank" >
                                            <button
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light w-100 mt-3"
                                            >
                                                FAQ
                                            </button>
                                        </a>
                                        <a href="https://epicpen.com/contact" rel="noreferrer" target="_blank" >
                                            <button
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light w-100 mt-3"
                                            >
                                                Contact us
                                            </button>
                                        </a>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : []
                    }
                    <Row>
                        <Col lg="6">
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Activation Codes</h4>
                                    <p className="card-title-desc">A list of all your current Epic Pen Pro activation codes.</p>
                                </CardHeader>
                                <CardBody>
                                    <ActivationCodeTable minimal={true} currentUserOnly={true} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Orders</h4>
                                    <p className="card-title-desc">A history of your orders of Epic Pen Pro.</p>
                                </CardHeader>
                                <CardBody>
                                    <OrderTable minimal={true} currentUserOnly={true} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;