import React, { useState, useEffect, useMemo, Fragment, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import {
    Badge,
    Col,
    Input,
    Row,
    Table,
} from "reactstrap";
import { GetAccessTokenSilentlyOptions, PaginatedAPIResponse, Order, EpicPenContext, Subscription2Upload, CustomOrder, BillingPeriod, CustomOrderMode, CustomOrderCurrency} from "../../epicPenHelpers/epicpen_licencing_api";
import { buildInfo } from "../../epicPenHelpers/build_info";
import { createActivationCode, createGUID, formatActivationCode, moneyToDisplayString, nameof } from "../../epicPenHelpers/epicPenUtils";
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from "./../LoadingSpinner";
import { data } from "jquery";
import { PaginatedTable, TableContainer } from "./TableContainer";
import EPModal, { EPModalButton } from "../EPModal";
import { debounce, set } from "lodash";
import cc from "currency-codes";
import getCurrencySymbol from 'currency-symbols';
import AsyncSelect from "react-select/async";

type CustomOrderUpload = Omit<CustomOrder, "id" | "userId" | "paddleCouponCode" | "fulfilled" | "subscriptionId">

const CustomOrderModal = (props: {isOpen: boolean, close: () => void }) => {

    const [isAdding, setIsAdding] = useState(false)


    //const [customOrderUpload, setCustomOrderUpload] = useState<{ [index: string]: any }>({
    //    [nameof<CustomOrder>("notes")]: "",
    //    [nameof<CustomOrder>("billingPeriod")]: "yearly",
    //    [nameof<CustomOrder>("percentageDiscount")]: 10.0,
    //    [nameof<CustomOrder>("recurring")]: false,
    //    [nameof<CustomOrder>("numOfLicences")]: 1,
    //    [nameof<CustomOrder>("endUserName")]: "",
    //    [nameof<CustomOrder>("paymentUserEmail")]: "",
    //    [nameof<CustomOrder>("endUserEmail")]: "",
    //    [nameof<CustomOrder>("notes")]: "",
    //    [nameof<CustomOrder>("PONumber")]: "",
    //})

    
    const [customOrderUpload, setCustomOrderUpload] = useState<CustomOrderUpload>({
        notes: "",
        mode: "standard",
        currency: "usd",
        billingPeriod: "yearly",
        percentageDiscount: 10.0,
        recurring: true,
        numOfLicences: 1,
        endUserName: "",
        paymentUserEmail: "",
        endUserEmail: "",
        PONumber: "",
    })

    const setCustomOrderUploadProperties = (properties : Partial<CustomOrderUpload>) => {
        setCustomOrderUpload((co) => Object.assign({}, co, properties))
    }

    console.log(customOrderUpload)

    const { api } = useContext(EpicPenContext);

    const requestData = useRef<{ id: string, abortController: AbortController }>({ id: createGUID(), abortController: new AbortController() });

    const loadOptions = debounce((inputValue: string, callback: (options: object[]) => void) => {
        const getUsersAsync = async () => {
            requestData.current.abortController.abort();
            const newRequestData = {
                id: createGUID(), abortController: new AbortController()
            };
            requestData.current = newRequestData;
            const users = await api.getUsers(0, 60, inputValue ?? "", newRequestData.abortController);
            if (newRequestData.id === requestData.current.id)
                callback(users.items.map((user) => { return { value: user.id, label: `${user.firstName} ${user.lastName}${user.company ? ` (${user.company})` : ""} - ${user.email}`, color: '#666666' } }));
        }
        getUsersAsync();
    }, 400);

    const addClick = async () => {
        setIsAdding(() => true)
        const result = await api.putCustomOrder(customOrderUpload);
        if (result.error) {

        } else {
            props.close();
        }
        console.log(result);
        setIsAdding(() => false)
    }
    return <EPModal
        title={"Custom order"}
        isOpen={props.isOpen}
        close={props.close}
        button={{
            text: isAdding ? "adding..." : "Add custom order",
            color: "blue",
            loading: isAdding,
            onClick: addClick
        }}
    >
        <div className="modal-body">
            <Row>
                <Col sm={12} md={6}><h5>Mode</h5>
                    <div className="mb-3">
                        <Input
                            type="select"
                            className="form-control"
                            defaultValue={customOrderUpload.billingPeriod}
                            onChange={(event) => setCustomOrderUploadProperties({mode: event.target.value as CustomOrderMode})}
                        >
                            <option value={"standard" as CustomOrderMode}>Standard</option>
                            <option value={"invoice" as CustomOrderMode}>Invoice</option>
                        </Input>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6}><h5>Billing period</h5>
                    <div className="mb-3">
                        <Input
                            type="select"
                            className="form-control"
                            defaultValue={customOrderUpload.billingPeriod}
                            onChange={(event) => setCustomOrderUploadProperties({billingPeriod: event.target.value as BillingPeriod})}
                        >
                            <option value={"monthly" as BillingPeriod}>Monthly</option>
                            <option value={"yearly" as BillingPeriod}>Yearly</option>
                        </Input>

                    </div>
                </Col>
                <Col sm={12} md={6}>
                    <h5>PO number</h5>
                    <div className="mb-3">
                        <Input
                            type="text"
                            className="form-control"
                            defaultValue={customOrderUpload.PONumber}
                            onChange={(event) => setCustomOrderUploadProperties({PONumber: event.target.value})}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <h5>Recurring</h5>
                    <div className="mb-3">
                        <Input
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={customOrderUpload.recurring}
                            onChange={(event) => setCustomOrderUploadProperties({recurring: Boolean(event.target.checked)})}
                        />
                    </div>
                </Col>
            <Col sm={12} md={6}>
                    <h5>Number of licences</h5>
                    <div className="mb-3">
                        <Input
                            type="number"
                            className="form-control"
                            defaultValue={customOrderUpload.numOfLicences}
                            onChange={(event) => setCustomOrderUploadProperties({numOfLicences: parseInt(event.target.value)})}
                        />
                    </div>
                </Col>
            </Row>
            

            <Row>
                {
                customOrderUpload.mode === "standard" ? <> 
                    <Col sm={12} md={6}>
                        <h5>Percentage discount</h5>
                        <div className="input-group mb-3">
                            <div className="input-group">
                                <div className="input-group-text">-%</div>
                                <Input
                                    type="number"
                                    className="form-control"
                                    defaultValue={customOrderUpload.percentageDiscount}
                                    min={10}
                                    max={100}
                                    onChange={(event) => setCustomOrderUploadProperties({percentageDiscount: parseFloat(event.target.value)})}
                                >
                                </Input>
                            </div>
                        </div>
                
                    </Col>
                </> : <>
                    <Col sm={12} md={6}><h5>Currency</h5>
                        <div className="mb-3">
                            <Input
                                type="select"
                                className="form-control"
                                defaultValue={customOrderUpload.currency}
                                onChange={(event) => setCustomOrderUploadProperties({currency: event.target.value as CustomOrderCurrency})}
                            >
                                <option value={"usd" satisfies CustomOrderCurrency}>US Dollar</option>
                                <option value={"eur" satisfies CustomOrderCurrency}>Euro</option>
                                <option value={"gbp" satisfies CustomOrderCurrency}>Pound Sterling</option>
                            </Input>

                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <h5>Amount</h5>
                        <div className="input-group mb-3">
                            <div className="input-group">
                                <div className="input-group-text">{customOrderUpload.currency === "usd" ? "$" : customOrderUpload.currency === "eur" ? "€" : customOrderUpload.currency === "gbp" ? "£" : ""}</div>
                                <Input
                                    type="number"
                                    className="form-control"
                                    defaultValue={customOrderUpload.percentageDiscount}
                                    min={10}
                                    max={100}
                                    onChange={(event) => setCustomOrderUploadProperties({percentageDiscount: parseFloat(event.target.value)})}
                                >
                                </Input>
                            </div>
                        </div>
                
                    </Col>
                </>
                }
            </Row>
            
            <h5>End user name</h5>
            <div className="mb-3">
                <Input
                    type="text"
                    className="form-control"
                    defaultValue={customOrderUpload.endUserName}
                    onChange={(event) => setCustomOrderUploadProperties({endUserName: event.target.value})}
                />
            </div>
            <h5>End user email</h5>
            <div className="mb-3">
                <Input
                    type="text"
                    className="form-control"
                    defaultValue={customOrderUpload.endUserEmail}
                    onChange={(event) => setCustomOrderUploadProperties({endUserEmail: event.target.value})}
                />
            </div>
            <h5>Payment user email</h5>
            <div className="mb-3">
                <Input
                    type="text"
                    className="form-control"
                    defaultValue={customOrderUpload.paymentUserEmail}
                    onChange={(event) => setCustomOrderUploadProperties({paymentUserEmail: event.target.value})}
                />
            </div>
            <h5>Notes</h5>
            <div className="mb-3">
                <Input
                    type="text"
                    className="form-control"
                    defaultValue={customOrderUpload.notes}
                    onChange={(event) => setCustomOrderUploadProperties({notes: event.target.value})}
                />
            </div>
        </div>
    </EPModal>



}

interface CustomOrderTableProps {
    minimal?: boolean,
    currentUserOnly: boolean
    subscription2id?: string
    userId?: string
}

const CustomOrderTable = (props: CustomOrderTableProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const { api, locale } = useContext(EpicPenContext);
    const [isOpen, setIsOpen] = useState(false)
    return PaginatedTable<CustomOrder>({
        minimal: props.minimal,
        fetchData: async (pageIndex, pageSize, searchString, abortController) => await api.getCustomOrders(pageIndex, pageSize, searchString, props.userId, abortController),
        addModal: {
            modal: <CustomOrderModal isOpen={isOpen} close={() => setIsOpen(false)} />, itemName: "custom order", open: () => setIsOpen(true)
        },
        columns: [
            {
                Header: "Billing period",
                accessor: "billingPeriod",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    return cellProps.value;
},
            },
            {
                Header: "Discount",
                accessor: nameof<CustomOrder>("percentageDiscount"),
                adminOnly: false,
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return `-%${cellProps.value}`;
                },
            },
            {
                Header: "Number of licences",
                accessor: "numOfLicences",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value;
                },
            },
            {
                Header: "End user name",
                accessor: "endUserName",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value;
                },
            },
            {
                Header: "End user email",
                accessor: "endUserEmail",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value;
                },
            },
            {
                Header: "Payment user email",
                accessor: "paymentUserEmail",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value;
                },
            },
            {
                Header: "PO number",
                accessor: nameof<CustomOrder>("PONumber"),
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value;
                },
            },
            {
                Header: "Fulfilled",
                accessor: nameof<CustomOrder>("fulfilled"),
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value ? <Badge className="bg-success">Fulfilled</Badge> : <Badge className="bg-warning">Pending</Badge>;
                },
            },
            {
                Header: "",
                accessor: "action",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    //console.log(JSON.stringify(cellProps));
                    if (cellProps.row.fastSpringOrderRef) {
                        return <a href={`https://licencing.epicpen.com/downloadOrderInvoice?orderId=${cellProps.row.original.id}`} target="_blank" rel="noreferrer"><button
                            type="button"
                            className="btn btn-light btn-sm"
                        >
                            Download invoice
                        </button></a>
                    } else if (cellProps.row.paddleReceiptURL) {
                        return <a href={cellProps.row.paddleReceiptURL} target="_blank" rel="noreferrer"><button
                            type="button"
                            className="btn btn-light btn-sm "
                        >
                            View receipt
                        </button></a>
                    } else {
                        return [];
                    }

                },
            },
            {
                Header: "",
                accessor: "action2",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    //console.log(JSON.stringify(cellProps));
                    return <Link to={`/customorders/${cellProps.row.original.id}`}><button
                        type="button"
                        className="btn btn-light btn-sm float-end"
                    >
                        View details
                    </button></Link>;
                },
            }
        ]
    })
}

export default CustomOrderTable;
