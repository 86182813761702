import React, { useState, useEffect, useMemo, useContext, Fragment } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Form,
    Label,
    Input,
    CardFooter,
    Badge,
    Modal,
    CardColumns,
    Button
} from "reactstrap";

import { getInvoices as onGetInvoices } from "../../store/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { GetAccessTokenSilentlyOptions, User, EpicPenContext } from "../../epicPenHelpers/epicpen_licencing_api";
import countryList from 'country-list'

import { Link, useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Auth0Context, useAuth0 } from '@auth0/auth0-react';
import { buildInfo } from "../../epicPenHelpers/build_info";
import LoadingSpinner from "../../epicPenComponents/LoadingSpinner";
import ActivationCodeTable from "../../epicPenComponents/tables/ActivationCodeTable";
import OrderTable from "../../epicPenComponents/tables/OrderTable";
import UserCard from "../../epicPenComponents/UserCard";
import EPModal from "../../epicPenComponents/EPModal";
import { text } from "node:stream/consumers";
import EPCard from "../../epicPenComponents/EPCard";
import { createGUID, emailSendStatusToStatusBadge, nameof } from "../../epicPenHelpers/epicPenUtils";
import { mapValues, set } from "lodash"
import { boolean } from "yup/lib/locale";
import { bool } from "yup";
import { CallClient, VideoStreamRenderer, LocalVideoStream, CallAgent, DeviceManager } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { AzureLogger, setLogLevel } from '@azure/logger';


interface ProfileParams {
    userId?: string
}



const ACSTest = () => {
    document.title = "ACSTest | Epic Pen";



    const profileUserId = useParams<ProfileParams>().userId;
    const { user, api }  = useContext(EpicPenContext);

    const [isLoading, setLoading] = useState<boolean>(true);
    const [callStatus, setCallStatus] = useState("");
    const [groupId, setGroupId] = useState("");
    const [callAgent, setCallAgent] = useState<CallAgent | null>(null);
    const [deviceManager, setDeviceManager] = useState<DeviceManager | null>(null);
    const [videoElement, setVideoElement] = useState<HTMLElement>(document.createElement("div"));

    const [profileUser, setProfileUser] = useState<User | null>(null);

    useEffect(() => {
        const getCallAgent = async () => {
            const callClient = new CallClient();
            const accessToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjVFODQ4MjE0Qzc3MDczQUU1QzJCREU1Q0NENTQ0ODlEREYyQzRDODQiLCJ4NXQiOiJYb1NDRk1kd2M2NWNLOTVjelZSSW5kOHNUSVEiLCJ0eXAiOiJKV1QifQ.eyJza3lwZWlkIjoiYWNzOmU0NjhjYTBlLTRkNDktNGMwMi1iMjJhLWU0OGZkOTNjNjdkOF8wMDAwMDAxOC1lNDEzLTFmOTItNTcwYy0xMTNhMGQwMGNiYzgiLCJzY3AiOjE3OTIsImNzaSI6IjE2ODQ3NzEyNzMiLCJleHAiOjE2ODQ4NTc2NzMsInJnbiI6ImFtZXIiLCJhY3NTY29wZSI6ImNoYXQsdm9pcCIsInJlc291cmNlSWQiOiJlNDY4Y2EwZS00ZDQ5LTRjMDItYjIyYS1lNDhmZDkzYzY3ZDgiLCJyZXNvdXJjZUxvY2F0aW9uIjoidW5pdGVkc3RhdGVzIiwiaWF0IjoxNjg0NzcxMjczfQ.DbGI54dff3Lsl3-e0wH42DbzdQjw2fEq50uxlbdX5bRKYrvKz4gE9-Z-jSE9fLuYUgL7c1q4c2y6EvxmOC-mSvygeL3opMROw4wEVod-YucGBBBgEVnfDjMfVzAJRGof8I89Y1ZqEGDyhEbd40GMJwiAH8LQUg2H9_JGWkNbce-6VxmlVLbvhxzwXoD_3NZ6rcHYT5QItPBy6nT7mwJxoYHNkS73urxAsH027bzdWDDY2zK9I2-TlX46ZEZ9OP3UACuKtCKbPwtxUnVAuxG3R11AhVs-7egA9Oke-ER0MNoAJFQEMd_2ntJ8iGNSX49mGPsw79ao_l_GOJEWYm0ibg";
            const acsIdentity = "8:acs:e468ca0e-4d49-4c02-b22a-e48fd93c67d8_00000018-d45d-f445-69ff-9c3a0d0032a5";
            let tokenCredential = new AzureCommunicationTokenCredential(accessToken);
            let callAgent = await callClient.createCallAgent(tokenCredential, { displayName: `${user.email}` })
            // Set up a camera device to use.
            let deviceManager = await callClient.getDeviceManager();
            await deviceManager.askDevicePermission({ video: true, audio: true });
            setDeviceManager(deviceManager);
            //await deviceManager.askDevicePermission({ audio: true });
            // Listen for an incoming call to accept.
            callAgent.on('incomingCall', async (args) => {
                try {
                    let incomingCall = args.incomingCall;
                    //acceptCallButton.disabled = false;
                    //startCallButton.disabled = true;
                } catch (error) {
                    console.error(error);
                }
            });
            setCallAgent(callAgent);
        };
        getCallAgent();
    }, []);

    const callInit = async () => {
        try {

            const createLocalVideoStream = async () => {
                const camera = (await deviceManager!.getCameras())[0];
                if (camera) {
                    return new LocalVideoStream(camera);
                } else {
                    console.error(`No camera device found on the system`);
                }
            }



            const newGroupId = createGUID();
            const videoStream = await createLocalVideoStream();
            const call = callAgent!.join({ groupId: newGroupId }, { videoOptions: { localVideoStreams: [videoStream!] } })
            
            //const call = callAgent.startCall([], {});
            setCallStatus(call.state);
            setGroupId(newGroupId);
            call.on("stateChanged", () => {
                setCallStatus(call.state);
            });
            call.on("remoteParticipantsUpdated", (args) => {
                alert(`remoteParticipantsUpdated`);
                args.added.forEach((participant) => {
                    alert(`new participant: ${participant.displayName}`)
                    participant.on("videoStreamsUpdated", () => {
                        participant.videoStreams.forEach(async (videoStream) => {
                            alert(`new participant video stream: ${videoStream.mediaStreamType}`)
                            const renderer = new VideoStreamRenderer(videoStream);
                            const view = await renderer.createView({ scalingMode: "Fit", isMirrored: false });
                            setVideoElement(view.target)
                        });
                    });
                    participant.videoStreams.forEach(async (videoStream) => {
                        alert(`new participant video stream: ${videoStream.mediaStreamType}`)
                        const renderer = new VideoStreamRenderer(videoStream);
                        const view = await renderer.createView({ scalingMode: "Fit", isMirrored: false });
                        setVideoElement(view.target)
                    });
                });
            })
            //alert(call.id)
            //callAgent.join({ groupId: "08f46e20-f61b-11ed-9caa-b9cd8db7c88f" }, { videoOptions })

            //startCallButton.disabled = false;
            //initializeCallAgentButton.disabled = true;
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        const getUserLocal = async () => {
            try {
                const user = profileUserId ? (await api.getUser(profileUserId)) : (await api.getCurrentUser());
                setProfileUser(() => user);
                setLoading(false)
            } catch (e: any) {
                console.log(`call api error: ${JSON.stringify(e)}`);
            }
        };
        getUserLocal();
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="ACS Test" />
                    <Row>
                        <Col lg={12}>
                            <div style={{ display: "flex", justifyContent: "center", backgroundColor: "gray", width: "100%" }}>
                                <div style={{ width: "1920px", backgroundColor: "green", aspectRatio: "1.777" }} ref={(ref) => ref?.appendChild(videoElement)}></div>
                            </div>
                            {groupId || !callAgent ? [] : <Button className="mt-2" onClick={() => callInit()}>Start call</Button>}
                            <p className="mt-2">Call status: {callStatus} </p>
                            <p className="mt-2">GroupId id: {groupId ? <a href={`https://app-acs-test2.azurewebsites.net/?groupId=${groupId}`}>{groupId}</a> : ""}</p>
                        </Col>
                        <Col lg={12}>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ACSTest;