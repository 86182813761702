import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";

import { Table, Row, Col, Button, Modal, Input } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./../filters";
import LoadingSpinner from "./../LoadingSpinner";
import { EpicPenContext, PaginatedAPIResponse } from "../../epicPenHelpers/epicpen_licencing_api";
import { createGUID } from "../../epicPenHelpers/epicPenUtils";
import { isFunction } from "formik";
import EPModal, { EPModalButton } from "../EPModal";

interface GlobalFilterProps {
    preGlobalFilteredRowsCount: number;
    globalFilter?: string;
    setGlobalFilter: (globalFilter: string) => void;
    setPageIndex: (index: number) => void
}


export type LoadingState = "idle" | "loadingWithCache" | "loading"; 


// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRowsCount,
    globalFilter,
    setGlobalFilter,
    setPageIndex
} : GlobalFilterProps) {
  const count = preGlobalFilteredRowsCount;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value:any) => {
      setGlobalFilter(value || undefined);
      setPageIndex(0);
  }, 600);

    return (
        <div className="search-box me-2 mb-2 d-inline-block" >
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
            </div>
  );
}

interface TableContainerProps<T> {
    columns: { Header: string; accessor: string }[];
    data: PaginatedAPIResponse<T>;
    isLoading: LoadingState
    fetchData: (pageIndex: number, pageSize: number, searchString: string) => void;
    addModal?: { modal: React.ReactNode, open : () => void, itemName: string }
    isMinimal: boolean
}

const TableContainer = <T,>({
    columns,
    data,
    isLoading,
    fetchData,
    addModal,
    isMinimal
}: TableContainerProps<T>) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        //pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageSize, globalFilter },
    } = useTable(
        {
            columns,
            data: data.items,
            initialState: { pageIndex: 0, pageSize: 20 },
            manualGlobalFilter: true,
            manualPagination: true
        },
        useGlobalFilter,
        usePagination
        );

    const [pageIndex, setPageIndex] = React.useState(0);
    const [refreshToken, setRefreshToken] = React.useState(createGUID());

    const AddModal: React.ReactNode = addModal?.modal ?? <React.Fragment></React.Fragment>

    React.useEffect(() => {
        console.log(`pageIndex : ${pageIndex}, pageSize : ${pageSize}, globalFilter : ${globalFilter}`)
        fetchData(pageIndex, pageSize, globalFilter)
    }, [pageIndex, pageSize, globalFilter, refreshToken])

    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
    };

    const onChangeInSelect = (event: any) => {
        setPageIndex(Number(event.target.value));
    };

    const onChangeInInput = (event: any) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };
    const pageCount = Math.max(1, Math.ceil(data.totalItemCount / pageSize));
    return (
        <Fragment>

            {AddModal}
            <Row className="mb-2">
                <Col md={isMinimal ? 6 : 3} xm={12} >
                    <select
                        className="form-select me-2 mb-2"
                        value={pageIndex}
                        onChange={onChangeInSelect}
                        disabled={isLoading !== "idle"}
                    >
                        {
                            Array.from({length: pageCount }, (x, index) =>
                            <option key={index} value={index}>
                                    Page {index + 1} of {pageCount}
                            </option>)
                        }
                    </select>
                </Col>
                {!isMinimal && (
                    <Col md={3} xm={12}>
                        <GlobalFilter
                            preGlobalFilteredRowsCount={data?.totalItemCount ?? 0}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            setPageIndex={setPageIndex} 
                            />
                    </Col>
                )}

                    <Col xm={12} >
                        <div className="text-end">
                            <button type="button" className="btn btn-light waves-effect waves-light" disabled={isLoading !== "idle"} onClick={() => { setRefreshToken(() => createGUID()) }}>
                                {isLoading !== "idle" ?
                                    <React.Fragment><i className="bx bx-loader bx-spin me-1" ></i>Loading</React.Fragment> : <React.Fragment><i className="bx bx-revision me-1" ></i>Refresh</React.Fragment>
                                }
                            </button>
                        {(!isMinimal && addModal) ?
                            <button type="button" className="btn btn-light waves-effect waves-light ms-3" onClick={() => { addModal.open() }}><i className="bx bx-plus me-1" ></i> Add {addModal?.itemName ?? ""}</button> : []
                                }
                        </div>
                    </Col>
                
            </Row>

            <div className="table-responsive">
                <Table hover {...getTableProps()} className={"table mb-0"}>
                    <thead className="table-light">
                        {headerGroups.map((headerGroup: any) => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <th key={column.id} >{column.adminOnly ? <u>{column.Header}</u> : <span>{column.Header}</span> }</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            isLoading == "loading" ? <tr><td colSpan={columns.length} ><LoadingSpinner /></td></tr> :
                            page.map((row: any) => {
                                prepareRow(row);
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map((cell: any) => {
                                                return (
                                                    <td key={cell.id} {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </Fragment>
                                );
                            })
                    }
                    </tbody>
                </Table>
            </div>

        </Fragment>
    );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};



interface Columns {
    Header: string,
    accessor: string
    includeInMinimal: boolean,
    adminOnly?: boolean
    Cell: (cellProps: any) => any
}

interface PaginatedTableProps<T> {
    minimal?: boolean
    fetchData: (pageIndex: number, pageSize: number, searchString: string, abortController: AbortController) => Promise<PaginatedAPIResponse<T>>
    columns: ((setRows: ((rowsTransformer: (currentRows: T[]) => T[]) => void)) => Columns[]) | Columns[]
    addModal?: { modal: React.ReactNode, open : () => void, itemName: string }
}


const PaginatedTable = <T,>(props: PaginatedTableProps<T>) => {
    const cache = useRef<any>({});

    const [items, setItems] = useState<PaginatedAPIResponse<T>>({ totalItemCount: 0, items: [] });

    const currentRequestGuid = useRef<string>(createGUID());
    const [requestsInProgress, setRequestsInProgress] = useState<Array<{ guid: string, loadingState: LoadingState, abortController: AbortController }>>([]);

    const loadingState = requestsInProgress.map((req) => req.loadingState).reduce((pre, cur) => (pre === "loading" || cur == "loading") ? "loading" : (pre === "loadingWithCache" || cur == "loadingWithCache") ? "loadingWithCache" : "idle", "idle")

    const { user } = useContext(EpicPenContext);



    const getOrdersLocal = async (pageIndex: number, pageSize: number, searchString: string) => {
        const requestGuid = createGUID();
        currentRequestGuid.current = requestGuid;
        try {
            requestsInProgress.forEach((req) => req.abortController.abort())
            let newAbortController = new AbortController();

            const cacheResult = cache.current[`${pageIndex}-${searchString}`] as PaginatedAPIResponse<T>;

            if (cacheResult) {
                setItems(() => cache.current[`${pageIndex}-${searchString}`]);
                setItems((oldUsers) => {
                    if (requestGuid === currentRequestGuid.current) {
                        return cacheResult;
                    } else {
                        return oldUsers;
                    }
                });
                setRequestsInProgress((requests) => requests.concat([{ guid: requestGuid, loadingState: "loadingWithCache", abortController: newAbortController }]))
            } else {
                setRequestsInProgress((requests) => requests.concat([{ guid: requestGuid, loadingState: "loading", abortController: newAbortController }]))
            }

            //const users = (await getUsers(getAccessToken, buildInfo.isDevMachine, pageIndex, pageSize, searchString, newAbortController))!;
            const users = (await props.fetchData(pageIndex, pageSize, searchString, newAbortController))!;
            setItems((oldUsers) => {
                if (requestGuid === currentRequestGuid.current) {
                    cache.current[`${pageIndex}-${searchString}`] = users;
                    return users;
                } else {
                    return oldUsers;
                }
            });
        } catch (e: any) {
            console.log(`call api error: ${JSON.stringify(e)}`);
        }
        setRequestsInProgress((requests) => requests.filter((req) => req.guid !== requestGuid))
    };
    //useEffect(() => {
    //    getOrdersLocal(pageIndex, pageSize, searchString);
    //}, [pageIndex, searchString]);

    const columns = useMemo(
        () => {
            const columns = isFunction(props.columns) ? props.columns((rowTransformer) => { setItems((items) => { return { totalItemCount: items.totalItemCount, items: rowTransformer(items.items) } }) }) : props.columns;
            return columns.filter((c) => props.minimal === true ? c.includeInMinimal : true && ((c.adminOnly === undefined || c.adminOnly === false) ? true : (user.role === "admin")))
        },
        [user]
    );
    const data = useMemo(() => items, [items]);

    return <React.Fragment>
        <TableContainer
            columns={columns}
            data={data}
            isLoading={loadingState}
            fetchData={(pageIndex, pageSize, searchString) => { getOrdersLocal(pageIndex, pageSize, searchString) }}
            isMinimal={props.minimal || false}
            addModal={props.addModal}
        />
    </React.Fragment>
}

export {TableContainer, PaginatedTable}