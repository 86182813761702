import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from "react";

//import Select from "react-select";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { PaginatedTable, TableContainer } from "./TableContainer";
import { Link } from "react-router-dom";
import {
    Badge,
    CardBody,
    CardFooter,
    Col,
    Input,
    Row,
    Table,
} from "reactstrap";
import { GetAccessTokenSilentlyOptions, ActivationCode, User, BillingPeriod, EpicPenContext, Subscription2, Subscription2Upload, Subscription } from "../../epicPenHelpers/epicpen_licencing_api";
import { buildInfo } from "../../epicPenHelpers/build_info";
import { activationCodeToStatusBadge, CopyButton, countryCodeToName, createActivationCode, createGUID, formatActivationCode, moneyToDisplayString, nameof, paddleNums } from "../../epicPenHelpers/epicPenUtils";
import { useAuth0 } from '@auth0/auth0-react';
import build from "react-countup";
import LoadingSpinner from "./../LoadingSpinner";
import ReactGA from "react-ga4";
import $ from 'jquery';
import { debounce, set } from 'lodash';
import EPModal, { EPModalButton } from "../EPModal";

interface ActivationCodeDisplay {
    id: string
    idFormatted?: string
    maxDevices: number
    deviceIdCount: number
}



interface ActivationCodeCellProps {
    activationCode: string
}

const ActivationCodeCell = (props: ActivationCodeCellProps) => {
    return <div>
        <span className="text-dark text-nowrap fw-medium font-monospace me-2">{formatActivationCode(props?.activationCode ?? "0000")}</span>
        <CopyButton value={formatActivationCode(props.activationCode)} />
    </div>;
};


interface PaddlePrice {
    "gross": number,
    "net": number,
    "tax": number
}

interface PaddleProduct {
    "product_id": number,
    "product_title": string,
    "currency": string,
    "vendor_set_prices_included_tax": boolean,
    "price": PaddlePrice,
    "list_price": PaddlePrice,
    "subscription": {
        "trial_days": number,
        "interval": string,
        "frequency": number,
        "price": PaddlePrice,
        "list_price": PaddlePrice
    }
}

interface PaddleProducts {
    "customer_country": string
    "products": PaddleProduct[]
}



interface PaddleResponse<T> {
    success: boolean
    response: T
}



type PricingPreview = {
    currency_code: string,
    details: {
        line_items: [{
            price: {
                id: string
                unit_price: {
                    currency_code: string
                }
            }
            formatted_unit_totals: {
                total: string
            }
            formatted_totals: {
                total: string
            }
            totals: {
                total: string
            }
            discounts: [{
                discount: {
                    code : string
                    type: "percentage" | "flat"
                    amount: string
                }
            }]
        }]
    }
}


type PricingPreviewResponse = {
    pricing: PricingPreview,
    yearlyMonthlyPricingPreview: PricingPreview
    pricingWithDiscount: PricingPreview
    yearlyMonthlyPricingPreviewWithDiscount: PricingPreview
}

const AddActivationCodeModal = (props: { isOpen: boolean, close: () => void }) => {
    const paddleSandboxMode = !buildInfo.isProduction;

    const { user } = useContext(EpicPenContext);
    const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>("monthly");

    const [numOfLicences, setNumOfLicences] = useState<number>(1);

    const [paddleInfo, setPaddleInfo] = useState<Map<number, {currency : string, pricePerLicenceMonthly : string, pricePerLicenceYearly : string}>>(new Map())

    const Paddle = (window as any).Paddle;
    const addActivationCodeClick = () => {
        const productId = (() => {
            if (billingPeriod === "monthly") {
                return (paddleSandboxMode ? paddleNums.sandbox : paddleNums.production).monthly.priceId
            } else if (billingPeriod === "yearly") {
                return (paddleSandboxMode ? paddleNums.sandbox : paddleNums.production).yearly.priceId
            } 
        })();

        const passthrough = {
            userId: user.id
        }
        Paddle.Checkout.open({
            settings: {
                displayMode: "overlay",
                theme: "light",
                locale: "en",
                allowLogout: false
            },
            items: [
                {
                    priceId: productId,
                    quantity: numOfLicences
                }
            ],
            customer: {
                email: user.email
            },
            customData: passthrough
        });
    }

    useEffect(() => {
        const getPaddleInfo = async (numOfLicences : number) => {
            try {
                const pricing = await (await (await fetch(`https://licencing.epicpen.com/pricing?countryCode=${(user.countryCode ?? "US").toUpperCase()}&sandbox=${!buildInfo.isProduction}&numOfLicence=${numOfLicences}`)).json()) as PricingPreviewResponse


                const getPriceFormatted = (productId: string) => {
                    return pricing.pricing.details.line_items.find((product) => product.price.id === productId)?.formatted_totals.total;//.find((product) => product.price.id === productId).formatted_totals.total;
                }

                setPaddleInfo((map) => {
                    let newMap =  new Map(map)
                    newMap.set(numOfLicences, {
                        currency: pricing.pricing.currency_code,
                        pricePerLicenceMonthly: getPriceFormatted(paddleNums.production.monthly.priceId)!,
                        pricePerLicenceYearly: getPriceFormatted(paddleNums.production.yearly.priceId)!
                    });
                    return newMap;
                })
                console.log(`getPaddleInfo downloaded successfully`);
            } catch (e: any) {
                console.log(`call api error: ${JSON.stringify(e)}`);
            }
        };
        getPaddleInfo(numOfLicences);
    }, [user, numOfLicences]);

    return <EPModal
        title="Add activation code"
        isOpen={props.isOpen}
        close={props.close}
        button={{
            text: "Add activation code",
            color: "blue",
            loading: false,
            onClick: addActivationCodeClick
        }}
    >{ paddleInfo.size === 0 ?<CardBody>< LoadingSpinner /></CardBody > :
    <React.Fragment>
        <div className="modal-body">
            <h5>Payment rate</h5>
            <div className="mb-3">
                <span className="display-6">{paddleInfo.has(numOfLicences) ? (billingPeriod === "monthly" ? paddleInfo.get(numOfLicences)!.pricePerLicenceMonthly! : paddleInfo.get(numOfLicences)!.pricePerLicenceYearly!) : "---"}</span>
                <span> / {billingPeriod === "monthly" ? "Month" : "Year"}</span>
            </div>
            <h5>Payment period</h5>
            <div className="btn-group mb-3" role="group" aria-label="Basic radio toggle button group" onChange={(event: any) => { console.log((event.target as any).value); setBillingPeriod(() => ((event.target as any).value)) }}>
                <input type="radio" className="btn-check" name="btnradio" value="monthly" id="radio_monthly" key="monthly" defaultChecked />
                <label className="btn btn-outline-secondary" htmlFor="radio_monthly">Monthly</label>
                <input type="radio" className="btn-check" name="btnradio" value="yearly" id="radio_yearly" key="yearly" />
                <label className="btn btn-outline-secondary" htmlFor="radio_yearly">Yearly</label>
            </div>
            <h5>Number of devices</h5>
            <div className="mb-0">
                <Input
                    type="number"
                    className="form-control"
                    name="numOfLicences"
                    contentEditable="true"
                    value={numOfLicences}
                    onChange={(event: any) => { setNumOfLicences(() => parseInt(((event.target as any).value))) }}
                    max="10000"
                    min="1"
                />

            </div>
        </div>
            </React.Fragment>
        }</EPModal>
}


const AddPermanentActivationCodeModal = (props: { isOpen: boolean, close: () => void }) => {

    const [isAdding, setIsAdding] = useState(false)

    const { api } = useContext(EpicPenContext);

    const [activationCodeUpload, setActivationCodeUpload] = useState<Subscription2Upload>({
        userId: "",
        notes: "",
        numOfLicences: 1,
        activationCode: createActivationCode(),
        displayName: "",
        alternativeAdminEmail: ""
    })

    console.log(activationCodeUpload)

    const { getAccessTokenSilently } = useAuth0();
    const getAccessToken = () => getAccessTokenSilently(GetAccessTokenSilentlyOptions);


    const requestData = useRef<{ id: string, abortController: AbortController }>({ id: createGUID(), abortController: new AbortController() });

    const loadOptions = debounce((inputValue: string, callback: (options: object[]) => void) => {
        const getUsersAsync = async () => {
            requestData.current.abortController.abort();
            const newRequestData = {
                id: createGUID(), abortController: new AbortController()
            };
            requestData.current = newRequestData;
            const users = await api.getUsers(0, 60, inputValue ?? "", newRequestData.abortController);
            if (newRequestData.id === requestData.current.id)
                callback(users.items.map((user) => { return { value: user.id, label: `${user.firstName} ${user.lastName}${user.company ? ` (${user.company})` : ""} - ${user.email}`, color: '#666666' } }));
        }
        getUsersAsync();
    }, 400);
 
    const addClick = () => {
        const add = async () => {
            setIsAdding(() => true)
            const result = await api.putSubscription2(activationCodeUpload)
            if (result.error) {

            } else {
                props.close();
            }
            console.log(result);
            setIsAdding(() => false)
        }
        add();
    }


    return <EPModal
        title={"permanent activation code"}
        isOpen={props.isOpen}
        close={props.close}
        button={{
            text: isAdding ? "adding..." : "Add permanent activation code",
            color: "blue",
            loading: isAdding,
            onClick: addClick
        }}
    >
        <div className="modal-body">
            <h5>Activation code</h5>
            <div className="input-group mb-3">
                <input type="text" className="form-control font-monospace font-weight-bold" placeholder="" aria-label="" value={formatActivationCode(activationCodeUpload.activationCode)} readOnly />

                <div className="input-group-append">
                    <button className="btn btn-primary waves-effect waves-light" type="button" onClick={() => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("activationCode"), createActivationCode())) }}>generate</button>
                </div>
            </div>

            <h5>Number of devices</h5>
            <div className="mb-3">
                <Input
                    type="number"
                    className="form-control"
                    name="numOfLicences"
                    contentEditable="true"
                    defaultValue={activationCodeUpload.numOfLicences}
                    onChange={(event: any) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("numOfLicences"), parseInt(((event.target as any).value)))) }}
                    max="10000"
                    min="1"
                />

            </div>
            <h5>Display name</h5>
            <div className="mb-3">
                <Input
                    type="text"
                    className="form-control"
                    name="displayName"
                    contentEditable="true"
                    defaultValue={activationCodeUpload.displayName}
                    onChange={(event: any) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("displayName"), ((event.target as any).value))) }}
                    max="10000"
                    min="1"
                />
            </div>
            <h5>Alternative admin email</h5>
            <div className="mb-3">
                <Input
                    type="email"
                    className="form-control"
                    name="displayName"
                    contentEditable="true"
                    defaultValue={activationCodeUpload.alternativeAdminEmail ?? ""}
                    onChange={(event: any) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("alternativeAdminEmail"), ((event.target as any).value))) }}
                />
            </div>
            <h5>Notes</h5>
            <div className="mb-3">
                <Input
                    type="text"
                    className="form-control"
                    name="displayName"
                    contentEditable="true"
                    defaultValue={activationCodeUpload.notes ?? ""}
                    onChange={(event: any) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("notes"), ((event.target as any).value))) }}
                />
            </div>
            <h5>Owner</h5>
            <div className="mb-0">
                <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions onChange={(value: any, action) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("userId"), value.value)) }} />
            </div>
        </div>
    </EPModal>



}

interface ActivationCodeTableProps {
    minimal?: boolean
    currentUserOnly: boolean
    userId?: string
    adminMode?: boolean
}



const ActivationCodeTable = (props: ActivationCodeTableProps) => {
    const { locale, api } = useContext(EpicPenContext);

    const [isModalOpen, setIsModalOpen] = useState(false)

    return PaginatedTable<Subscription2>({
        minimal: props.minimal,
        fetchData: async (pageIndex, pageSize, searchString, abortController) => await api.getSubscription2s(pageIndex, pageSize, searchString, props.currentUserOnly, props.userId, abortController),
        addModal: (!props.adminMode ? { modal: <AddActivationCodeModal isOpen={isModalOpen} close={() => setIsModalOpen(false)} />, itemName: "activation code", open: () => setIsModalOpen(true) } : { modal: <AddPermanentActivationCodeModal isOpen={isModalOpen} close={() => setIsModalOpen(false)} />, itemName: "permanent activation code", open: () => setIsModalOpen(true) } ),
        columns: [
            {
                Header: "Activation code",
                accessor: "activationCode",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    return <ActivationCodeCell activationCode={cellProps.value} />;
                },
            },
            {
                Header: "Activated devices",
                accessor: "deviceIdCount",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    const fullyActivated = cellProps.value === cellProps.row.original.numOfLicences;
                    const text = `${cellProps.value} / ${cellProps.row.original.numOfLicences}`;
                    return fullyActivated ? <b>{text}</b> : text;
                },
            },
            {
                Header: "License type",
                accessor: "mode",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value === "standard" ? "Subscription" : "Lifetime";
                },
            },
            {
                Header: "Status",
                accessor: "status",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return activationCodeToStatusBadge(cellProps.row.original as Subscription2)
                },
            },
            {
                Header: "Billing period",
                accessor: "billingPeriod",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.row.original.mode === "standard" ? (cellProps.value === "monthly" ? "Monthly" : "Yearly") : "";
                },
            },
            {
                Header: "Creation date",
                accessor: "creationDate",
                includeInMinimal: false,
                adminOnly: true,
                Cell: (cellProps: any) => {
                    return `${new Date(cellProps.value).toLocaleTimeString(locale)} ${new Date(cellProps.value).toLocaleDateString(locale)}`;
                },
            },
            {
                Header: "User",
                accessor: "user.email",
                includeInMinimal: false,
                adminOnly: true,
                Cell: (cellProps: any) => {
                    return <Link to={`/profile/${cellProps.row.original.user?.id}`}>{cellProps.value}</Link>;
                },
            },
            {
                Header: "Country",
                accessor: "user.countryCode",
                includeInMinimal: false,
                adminOnly: true,
                Cell: (cellProps: any) => {
                    return countryCodeToName(cellProps.value);
                },
            },
            {
                Header: "",
                accessor: "action",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    //console.log(JSON.stringify(cellProps));
                    return <Link to={`/activationcodes/${cellProps.row.original.id}`}><button
                        type="button"
                        className="btn btn-light btn-sm float-end"
                    >
                        View details
                    </button></Link>;
                },
            }
        ]
    })
}


export default ActivationCodeTable;
