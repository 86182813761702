import React, { Fragment, useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { EmailSendStatus, Subscription2 } from "./epicpen_licencing_api";

const formatActivationCode = (activationCode: string) => {
    return Array.from(Array(6).keys()).map(i => activationCode.substr(i * 4, 4)).join("-");
};

const moneyToDisplayString = (amount: number, currency: string | null) => {
    let formatter = currency ? new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,
    }) : null;
    return formatter ? formatter.format(amount) : amount.toString()

}

const countryCodeToName = (countryCode: string) => {
    try {
        let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
        return regionNames.of(countryCode); 
    } catch (e) {
        return "";
    }
}

const createGUID = (): string => {
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const createActivationCode = (): string => {
    return Array.from(Array(4 * 6).keys()).map(() => { return (crypto.getRandomValues(new Uint8Array(1))[0] >> 4).toString(16) }).join("")
}

const nameof = <T,>(name: Extract<keyof T, string>): string => name;

export type PaddleMode = 'onceoff' | 'monthly' | 'yearly';



const paddleNums = {
    sandbox: {
        vendorId: 8099,
        onceOff: { priceId: "pri_01hcwbgecmycd154f0m7vabw49" },
        monthly: { priceId: "pri_01hcmp7wm1r1yejyke3zv20z4v" },
        yearly: { priceId: "pri_01hcmmdy16x8av9jddas45t5j0" },
        withTrial: {
            monthly: { priceId: "pri_01hcmp7gsrpzq7s55yc18v8cfb" },
            yearly: { priceId: "pri_01hcmnafpwatcnppc6f840e0j1" },
        }
    },
    production: {
        vendorId: 151418,
        onceOff: { priceId: "pri_01hcmeagqpt8rc8xvdhpv9p9ap" },
        monthly: { priceId: "pri_01hcme8m9as56be0xfkf2r96sn" },
        yearly: { priceId: "pri_01hcme6z8x5gv75bsj9hbn13r8" },
        withTrial: {
            monthly: { priceId: "pri_01hcme4hbmedr8jggf2mh274fk" },
            yearly: { priceId: "pri_01hcme2weqfv96edvkw5qq637h" },
        }
    }
}

const isTrialActive = (subscription2: Subscription2) => {
    return !subscription2.firstActivation || (((new Date(subscription2.firstActivation).getTime() + subscription2.permanentModeTrialLengthInDays * (1000 * 60 * 60 * 24))) > Date.now())
}

const activationCodeToStatusBadge = (subscription2: Subscription2) => {
    const perModeStatusToBadge = (subscription2: Subscription2) => {
        if (subscription2.isPermenentModeTrialActivationCode) {
            if (isTrialActive(subscription2)) {
                return <Badge className="bg-success">Active trial</Badge>;
            } else {
                return <Badge className="bg-danger">Expired trial</Badge>;
            }
        } else {
            if (subscription2.permanentModeStatus === "active") {
                return <Badge className="bg-success">Active</Badge>;
            } else if (subscription2.permanentModeStatus === "refunded") {
                return <Badge className="bg-danger">Refunded</Badge>;
            }
        }
        return null;
    }
    const modeStatusToBadge = (modeStatus: string | null) => {
        if (modeStatus === "active") {
            return <Badge className="bg-success">Active</Badge>;
        } else if (modeStatus === "trialing") {
            return <Badge className="bg-success">Trialing</Badge>;
        } else if (modeStatus === "pastdue") {
            return <Badge className="bg-warning">Past due</Badge>;
        } else if (modeStatus === "paused") {
            return <Badge className="bg-warning">Paused</Badge>;
        } else if (modeStatus === "cancellationPending") {
            return <Badge className="bg-warning">Cancellation pending</Badge>;
        } else if (modeStatus === "deleted") {
            return <Badge className="bg-danger">Cancelled</Badge>;
        }
        return null;
    }
    return subscription2.mode === "standard" ? modeStatusToBadge(subscription2.status) : perModeStatusToBadge(subscription2);
}

//EmailSendStatus

const emailSendStatusToStatusBadge = (emailSendStatus: EmailSendStatus) => {
    //"unsent" | "processed" | "dropped" | "delivered" | "deferred" | "bounce" | "blocked"
    if (emailSendStatus === "unsent") {
        return <Badge className="bg-light">Unsent</Badge>;
    } else if (emailSendStatus === "processed") {
        return <Badge className="bg-warning">Processed</Badge>;
    } else if (emailSendStatus === "dropped") {
        return <Badge className="bg-danger">Dropped</Badge>;
    } else if (emailSendStatus === "delivered") {
        return <Badge className="bg-success">Delivered</Badge>;
    } else if (emailSendStatus === "deferred") {
        return <Badge className="bg-danger">Deferred</Badge>;
    } else if (emailSendStatus === "bounce") {
        return <Badge className="bg-danger">Bounce</Badge>;
    } else if (emailSendStatus === "blocked") {
        return <Badge className="bg-danger">Blocked</Badge>;
    }

}

const CopyButton = (props: { value: string }) => {
    const [showCopied, setShowCopied] = useState<boolean>(false);
    const [copyTimeout, setCopyTimeout] = useState<NodeJS.Timeout | null>(null);

    const copyToClipboard = (str: string) => {
        navigator.clipboard.writeText(str);
    };
    const handleCopyClick = () => {
        //ReactGA.event("ep_copy_activation_code_clicked");
        if (copyTimeout !== null) {
            clearTimeout(copyTimeout);
        }
        copyToClipboard(props.value);
        setShowCopied(true);

        setCopyTimeout(setTimeout(() => {
            setShowCopied(false);
        }, 1000));

    }
    return <button
        type="button"
        className="btn btn-light btn-sm"
        onClick={() => handleCopyClick()}
    >
        {showCopied ? "Copied" : "Copy"}
    </button>
}


export {
    isTrialActive,
    formatActivationCode,
    moneyToDisplayString,
    countryCodeToName,
    createGUID,
    paddleNums,
    createActivationCode,
    nameof,
    activationCodeToStatusBadge,
    CopyButton,
    emailSendStatusToStatusBadge
}