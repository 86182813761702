import React, { useState, useEffect, useMemo } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from "reactstrap";

import { getInvoices as onGetInvoices } from "../../store/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";


import { Link, useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react';
import { buildInfo } from "../../epicPenHelpers/build_info";
import OrderTable from "../../epicPenComponents/tables/OrderTable";
import CustomOrderTable from "../../epicPenComponents/tables/CustomOrderTable";

interface Order {
    PONumber: string
    coupons: string
    currency: string
    displayName: string
    fastSpringOrderRef: string
    fastspringProductPath: string
    fastspringTotalUSDPayout: string
    howIUse: string
    id: string
    individualActivationCodes: boolean
    licenceType: string
    licenceeId: string
    moneyAmount: number
    notes: string
    numOfLicences: number
    orderDate: string
    orderNum: number
    stackCommerceTransactionId: string
    stripeId: string
}

interface OrderDisplay {
    id: string
    orderDate: string
    fastSpringOrderRef: string
    moneyAmount: string
}

interface OrdersProps {
    currentUserOnly: boolean
}

const CustomOrders = (props: OrdersProps) => {
    document.title = "Custom Orders | Epic Pen";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="Custom orders" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CustomOrderTable currentUserOnly={false} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CustomOrders;