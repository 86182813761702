import React, { useContext } from "react";
import build from "react-countup";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { EpicPenContext } from "../../epicPenHelpers/epicpen_licencing_api";
//import generatedBuildInfo from '../../generatedBuildInfo.json';

import { buildInfo } from "./../../epicPenHelpers/build_info";

const buildDate = new Date(buildInfo.buildDate);

const shortEnv =  buildInfo.environment === "production" ? "prod" : (buildInfo.environment === "development" ? "dev" : "unknown");


const Footer = () => {

    const { user } = useContext(EpicPenContext)

    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col md={6}>{buildDate.getFullYear()} © <a href="https://tankstudios.ltd" target="_blank" rel="noreferrer">Tank Studios Limited</a>.</Col>
                        <Col md={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                {
                                    buildInfo.isProduction && user.role !== "admin" ? <React.Fragment>Epic Pen Accounts</React.Fragment> :
                                        <React.Fragment>(Git commit: <a href={`https://github.com/tank-studios/epic-pen-accounts/commit/${buildInfo.gitCommitHash}`} target="_blank" rel="noreferrer" className="ms-1 text-decoration-underline">{buildInfo.gitCommitHash.substring(0, 7)}</a> - {shortEnv}) - Epic Pen Accounts</React.Fragment>
                                }
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
