import React, { useState, useEffect, useMemo, useContext, Fragment } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Form,
    Label,
    Input,
    CardFooter,
    Badge,
    Modal,
    CardColumns
} from "reactstrap";

import { getInvoices as onGetInvoices } from "../../store/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { GetAccessTokenSilentlyOptions, User, EpicPenContext } from "../../epicPenHelpers/epicpen_licencing_api";
import countryList from 'country-list'

import { Link, useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Auth0Context, useAuth0 } from '@auth0/auth0-react';
import { buildInfo } from "../../epicPenHelpers/build_info";
import LoadingSpinner from "../../epicPenComponents/LoadingSpinner";
import ActivationCodeTable from "../../epicPenComponents/tables/ActivationCodeTable";
import OrderTable from "../../epicPenComponents/tables/OrderTable";
import UserCard from "../../epicPenComponents/UserCard";
import EPModal from "../../epicPenComponents/EPModal";
import { text } from "node:stream/consumers";
import EPCard from "../../epicPenComponents/EPCard";
import { emailSendStatusToStatusBadge, nameof } from "../../epicPenHelpers/epicPenUtils";
import { mapValues, set } from "lodash"
import { boolean } from "yup/lib/locale";
import { bool } from "yup";


interface CancelAccountModalsProps {
    signedInUserId: string
    userId: string
    showModal: boolean
    closeModal: () => void
}

const CancelAccountModals = (props: CancelAccountModalsProps) => {


    const [resultModal, setResultModal] = React.useState<{ message: string, success: boolean } | null>(null);

    const [accountClosingInProgress, setAccountClosingInProgress] = React.useState(false);

    const { logout } = useAuth0();
    const { api } = useContext(EpicPenContext);


    const tryCloseAccount = async () => {
        setAccountClosingInProgress(true);
        try {
            const result = (await api.closeUserAccount(props.userId));
            props.closeModal();

            if (buildInfo.isDevMachine) {
                result.error = { errorCode: "user_account_has_active_activation_code" }
            }

            if (result.error) {

                if (result.error.errorCode === "user_account_has_active_activation_code") {
                    setResultModal({ message: "Your account cannot be closed as you have active Epic Pen Pro subscriptions. Please cancel any remaining activation codes and try again.", success: false });
                } else {
                    setResultModal({ message: "There was an error in closing your account. Please contact support.", success: false });
                }
            } else {
                setResultModal({ message: "Your account was successfully closed. You will now be logged out..", success: true });
            }
        } catch (e) {
            setResultModal({ message: "There was an error in closing your account. Please contact support.", success: false })
        }
        props.closeModal();
        setAccountClosingInProgress(false);
    };


    return <React.Fragment>
        <EPModal
            title="Close account"
            isOpen={props.showModal}
            close={() => props.closeModal()}
            button={{
                text: accountClosingInProgress ? "Closing account..." : "Close my Epic Pen account",
                color: "red",
                loading: accountClosingInProgress,
                onClick: () => tryCloseAccount()
            }}
        >
            <div className="modal-body">
                <p>Closing your account will mean you lose access to any Epic Pen Pro activation codes you have. Before closing your account please make sure any activation code subscriptions you have are cancelled.
                </p>
                <p>Closing your Epic pen account cannot be undone.
                </p>
            </div>
        </EPModal>

        <EPModal
            title={resultModal?.success ? "Success" : "Error"}
            isOpen={Boolean(resultModal)}
            close={() => setResultModal(null)}
            button={{
                text: resultModal?.success ? "Log out" : "Okay",
                color: "blue",
                loading: false,
                onClick: (() => { if (resultModal?.success) { if (props.signedInUserId === props.userId) { logout(); } } setResultModal(null); })
            }}
        >
            <div className="modal-body">
                <p>{resultModal?.message}
                </p>
            </div>
        </EPModal>
    </React.Fragment>
}


interface ChangeEmailModalsProps {
    userId: string
    showModal: boolean
    closeModal: () => void
}

const ChangeEmailModals = (props: ChangeEmailModalsProps) => {


    const [resultModal, setResultModal] = React.useState<{ message: string, success: boolean } | null>(null);
    const [newEmail, setNewEmail] = React.useState<string>("");

    const [emailChangingInProgress, setEmailChangingInProgress] = React.useState(false);

    const { api } = useContext(EpicPenContext);


    const tryCloseAccount = async () => {
        setEmailChangingInProgress(true);
        try {
            const result = (await api.changeUserEmail(props.userId, newEmail));
            props.closeModal();

            if (buildInfo.isDevMachine) {
                //result.error = { errorCode: "user_account_has_active_activation_code" }
            }
            if (result.error) {
                setResultModal({ message: "There was an error when attempting to change your email. Please contact support.", success: false });
                
            } else {
                setResultModal({ message: "A verification request has been sent to the email address you provided. Once you verify your new email address your account email address will be updated.", success: true });
            }
        } catch (e) {
            setResultModal({ message: "There was an error when attempting to change your email. Please contact support.", success: false });
        }
        props.closeModal();
        setEmailChangingInProgress(false);
    };


    return <React.Fragment>
        <EPModal
            title="Change email"
            isOpen={props.showModal}
            close={() => props.closeModal()}
            button={{
                text: emailChangingInProgress ? "Changing email..." : "Change email",
                color: "blue",
                loading: emailChangingInProgress,
                onClick: () => tryCloseAccount()
            }}
        >
            <div className="modal-body">
                <p>Please enter your new email address
                </p>
                <div className="mb-3">
                    <Input
                        type="text"
                        className="form-control"
                        defaultValue={newEmail}
                        onChange={(event: any) => { setNewEmail(((event.target as any).value)) }}
                    />
                </div>
            </div>
        </EPModal>

        <EPModal
            title={resultModal?.success ? "Success" : "Error"}
            isOpen={Boolean(resultModal)}
            close={() => setResultModal(null)}
            button={{
                text: "Okay",
                color: "blue",
                loading: false,
                onClick: (() => setResultModal(null))
            }}
        >
            <div className="modal-body">
                <p>{resultModal?.message}
                </p>
            </div>
        </EPModal>
    </React.Fragment>
}

interface UserProfileCardProps {
    user: User | null
}
const UserProfileCard = (props: UserProfileCardProps) => {

    const { api, user } = useContext(EpicPenContext);
    const [userDetails, setUserDetails] = useState<{ [index: string]: any }>({
        [nameof<User>("countryCode")]: "-",
        [nameof<User>("firstName")]: "",
        [nameof<User>("lastName")]: "",
        [nameof<User>("company")]: "",
    })
    const [cancelAccountModalVisible, setCancelAccountModalVisible] = useState<boolean>(false);
    const [changeEmailModalVisible, setChangeEmailModalVisible] = useState<boolean>(false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [resultModal, setResultModal] = React.useState<{ message: string, success: boolean } | null>(null);

    console.log(userDetails)
    const submitUserDetails = async () => {
        if (props.user) {
            setSubmitting(true);
            const result = await api.patchUser((props.user.id)!, userDetails);
            if (result?.error) {
                console.log(result?.error)
                setResultModal({ message: "There was an error updating the profile. Please try again later.", success: false });
            }
            setSubmitting(false);
        }
    }
    useEffect(() => {
        if (props.user) {
            setUserDetails((oldUserDetails) => mapValues(oldUserDetails, (o, a, e) => (props.user as any)[a]));
        }
    }, [props.user]);


    const requestPasswordReset = async () => {
        if (props.user) {
            const result = await api.requestPasswordReset(props.user.id);
            if (result?.error) {
                console.log(result?.error)
                setResultModal({ message: "There was an error requesting the passwrod reset. Please try again later.", success: false });
            } else {
                setResultModal({ message: "Password reset requested. An password reset email has been sent.", success: true });
            }
        }
    };

    const requestChangeEmail = async () => {
        if (props.user) {
            const result = await api.requestPasswordReset(props.user.id);
            if (result?.error) {
                console.log(result?.error)
                setResultModal({ message: "There was an error requesting the passwrod reset. Please try again later.", success: false });
            } else {
                setResultModal({ message: "Password reset requested. An password reset email has been sent.", success: true });
            }
        }
    };

    const actionButtons = [
        {
            header: "Change password",
            action: () => { requestPasswordReset() }
        },
        {
            header: "Change email",
            action: () => { setChangeEmailModalVisible(true) }
        },
        {
            header: "Close account",
            action: () => { setCancelAccountModalVisible(true) }
        }];

    //{ header: "Request password reset", action: () => { requestPasswordReset() } },
    return <EPCard title="Profile details" titleIcon="mdi-account" primaryButton={{ header: isSubmitting ? "Saving..." : "Save", action: () => { submitUserDetails() }, loading: isSubmitting, danger: false }} actionButtons={{ buttons: actionButtons, danger: false }}>
        {props.user ? <CancelAccountModals userId={props.user.id} signedInUserId={user.id} showModal={cancelAccountModalVisible} closeModal={() => setCancelAccountModalVisible(false)} /> : []}
        {props.user ? <ChangeEmailModals userId={props.user.id} showModal={changeEmailModalVisible} closeModal={() => setChangeEmailModalVisible(false)} /> : []}
        <EPModal
            title={resultModal?.success ? "Success" : "Error"}
            isOpen={Boolean(resultModal)}
            close={() => setResultModal(null)}
            button={{
                text: "Okay",
                color: "blue",
                loading: false,
                onClick: () => setResultModal(null)
            }}
        >
            <div className="modal-body">
                <p>{resultModal?.message ?? ""}
                </p>
            </div>
        </EPModal>


            <div className="mb-3">
                <Label className="form-label">
                    Email
                </Label>
                <p>{props.user?.email} {props.user?.emailVerified ? <Badge className="bg-success">Verified</Badge> : <Badge className="bg-danger">Unverfied</Badge>}</p>
                {
                    false ? <div className="mt-4">
                        <button type="submit" className="btn btn-primary w-md">
                            Change email
                        </button>
                    </div> : []
                }
            </div>

            <Row>
                <Col md={6}>
                    <div className="mb-3">
                        <Label className="form-label" htmlFor="formrow-email-input">
                            First name
                        </Label>
                        <Input
                            type="text"
                            className="form-control"
                            name="firstName"
                            placeholder="First name"
                            contentEditable={!isSubmitting}
                            defaultValue={userDetails?.firstName || ""}
                            maxLength={100}
                            onChange={(event: any) => { setUserDetails((user) => set(user, nameof<User>("firstName"), ((event.target as any).value))) }}
                        />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <Label
                            className="form-label"
                            htmlFor="formrow-password-input"
                        >
                            Last name
                        </Label>
                        <Input
                            type="text"
                            className="form-control"
                            name="lastName"
                            placeholder="Last name"
                            contentEditable={!isSubmitting}
                            defaultValue={userDetails?.lastName || ""}
                            maxLength={100}
                            onChange={(event: any) => { setUserDetails((user) => set(user, nameof<User>("lastName"), ((event.target as any).value))) }}
                        />
                    </div>
                </Col>
            </Row>

        <div className="mb-3">
            <Label className="form-label">
                Organisation
            </Label>
            <Input
                type="text"
                className="form-control"
                name="company"
                placeholder="Organisation"
                contentEditable={!isSubmitting}
                defaultValue={userDetails?.company || ""}
                maxLength={100}
                onChange={(event: any) => { setUserDetails((user) => set(user, nameof<User>("company"), ((event.target as any).value))) }}
            />
        </div>
            <div className="mb-3">
                <Label className="form-label">
                    Country
                </Label>
                <select
                    className="form-select me-2 mb-2"
                    value={(userDetails[nameof<User>("countryCode")] || "-").toUpperCase()}
                    name="countryCode"
                    onChange={(event: any) => { setUserDetails((user) => set(user, nameof<User>("countryCode"), ((event.target as any).value))) }}
                >
                    {
                        countryList.getData().concat([{ name: "None specified", code: "-" }]).map(country => <option key={country.name} value={country.code.toUpperCase()}>
                            {country.name}
                        </option>)
                    }
                </select>
            </div>
    </EPCard>
}

interface AdminUserDetailsCardProps {
    user : User | null
}

const AdminUserDetailsCard = (props: AdminUserDetailsCardProps) => {

    const { api, locale } = useContext(EpicPenContext);
    const [userDetails, setUserDetails] = useState<{ [index: string]: any }>({
        [nameof<User>("notes")]: "",
    })
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const [resultModal, setResultModal] = React.useState<{ message: string, success: boolean } | null>(null);
    
    console.log(userDetails)
    const submitUserDetails = async () => {
        if (props.user) {
            setSubmitting(true);
            const result = await api.patchUser((props.user.id)!, userDetails);
            if (result?.error) {
                console.log(result?.error)
                setResultModal({ message: "There was an error updating the profile. Please try again later.", success: false });
            }
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (props.user) {
            setUserDetails((oldUserDetails) => mapValues(oldUserDetails, (o, a, e) => (props.user as any)[a]))
        }
    }, [props.user]);

    const resendEmailValidationEmail = async () => {
        if (props.user) {
            const result = await api.resendEmailVerificationEmail(props.user.id);
            if (result?.error) {
                console.log(result?.error)
                setResultModal({ message: "There was an error sending the email. Please try again later.", success: false });
            } else {
                setResultModal({ message: "The email was successfully sent.", success: true });
            }
        }
    };


    return <EPCard title="Profile details" titleIcon="mdi-account" danger={true} primaryButton={{ header: isSubmitting ? "Saving..." : "Save", action: () => { submitUserDetails() }, loading: isSubmitting, danger: true }} actionButtons={{ buttons: [{ header: "Re-send email verification email", action: () => { resendEmailValidationEmail() } }] }}>
        {!props.user ? <LoadingSpinner /> : <React.Fragment>

            <EPModal
                title={resultModal?.success ? "Success" : "Error"}
                isOpen={Boolean(resultModal)}
                close={() => setResultModal(null)}
                button={{
                    text: "Okay",
                    color: "blue",
                    loading: false,
                    onClick: () => setResultModal(null)
                }}
            >
                <div className="modal-body">
                    <p>{resultModal?.message ?? ""}
                    </p>
                </div>
            </EPModal>
            <div className="mb-3">
                <Label className="form-label">
                    id
                </Label>
                <p>{props.user.id}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Register date
                </Label>
                <p>
                    {new Date(props.user.registerDate).toLocaleTimeString(locale)} {new Date(props.user.registerDate).toLocaleDateString(locale)}</p>
            </div>

<div className="mb-3">
    <Label className="form-label">
        View on paddle
    </Label>
    <p>
        {props.user.paddleCustomerId ? <a href={`https://vendors.paddle.com/customers-v2/${props.user.paddleCustomerId}`} target="_blank" rel="noreferrer" >View customer on Paddle</a> : "no paddle customer id"}
    </p>
</div>

<div className="mb-3">
    <Label className="form-label">
        View on Mixpanel
    </Label>
    <p>
        <a href={`https://eu.mixpanel.com/project/2675707/view/3212819/app/profile#distinct_id=user_id.${props.user.id}`} target="_blank" rel="noreferrer" >View customer on Mixpanel</a>
    </p>
</div>

            
            <div className="mb-3">
                <Label className="form-label">
                    Verification email status
                </Label>
                <p>{props.user ? emailSendStatusToStatusBadge(props.user.emailVerificationEmailStatus) : ""}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Change request email
                </Label>
                <p>{props.user?.changeRequestEmail ?? "none"}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Email change verification email status
                </Label>
                <p>{props.user ? emailSendStatusToStatusBadge(props.user.emailChangeVerificationEmailStatus) : ""}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    User permission granted for email marketing
                </Label>
                <p>{props.user?.userPermissionGrantedForEmailMarketing?.toString() ?? ""}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Notes
                </Label>
                <Input
                    type="text"
                    className="form-control"
                    name="company"
                    placeholder="notes"
                    contentEditable={true}
                    defaultValue={props.user?.notes || ""}
                    maxLength={1000}
                    onChange={(event: any) => { setUserDetails((user) => set(user, nameof<User>("notes"), ((event.target as any).value))) }}
                />
            </div>

        </React.Fragment>
        }
    </EPCard>
}

interface ProfileParams {
    userId?: string
}

const Profile = () => {
    document.title = "Profile | Epic Pen";

    const profileUserId = useParams<ProfileParams>().userId;
    const { user, api }  = useContext(EpicPenContext);

    const [isLoading, setLoading] = useState<boolean>(true);




    const [profileUser, setProfileUser] = useState<User | null>(null);
    useEffect(() => {
        const getUserLocal = async () => {
            try {
                const user = profileUserId ? (await api.getUser(profileUserId)) : (await api.getCurrentUser());
                setProfileUser(() => user);
                setLoading(false)
            } catch (e: any) {
                console.log(`call api error: ${JSON.stringify(e)}`);
            }
        };
        getUserLocal();
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="Profile" />
                    <Row>
                        <Col lg={6}>
                            <UserProfileCard user={profileUser} />
                        </Col>
                        <Col lg={6}>
                            {false ? <Card>
                                <CardHeader>
                                    <h5 className="my-0">
                                        <i className="mdi mdi-shield-lock-open me-3"></i>Security
                                    </h5>
                                </CardHeader>
                                <CardBody className="p-4">
                                    {isLoading ? <LoadingSpinner /> : <React.Fragment>
                                        <div className="">
                                            <div className="form-check form-switch mb-3" dir="ltr">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customSwitch1"
                                                    defaultChecked
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customSwitch1"
                                                >
                                                    Enable multi-factor authentication
                                                </label>
                                            </div>

                                        </div>

                                    </React.Fragment>
                                    }
                                </CardBody>
                            </Card> : []
                            }
                        </Col>
                    </Row>
                    {
                        user.role === "admin" ? <Row>
                            <Col lg="12">
                                <Card className="border border-danger">
                                    <div className="card-header bg-transparent border-danger">
                                        <h5 className="my-0 text-danger">
                                            <i className="mdi mdi-alert-outline me-3"></i>Admin
                                        </h5>
                                    </div>
                                    <CardBody>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <AdminUserDetailsCard user={profileUser}/>
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : []
                    }
                    {
                        profileUserId ?  <Row>

                            <Col lg="6">
                                <Card>
                                    <CardHeader>
                                        <h4 className="card-title">Activation codes</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <ActivationCodeTable currentUserOnly={false} userId={profileUserId} /> 
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6">
                                <Card>
                                    <CardHeader>
                                        <h4 className="card-title">Orders</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <OrderTable currentUserOnly={false} userId={profileUserId} /> 
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : []
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Profile;