import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem, Modal, Input, Alert, CardHeader, CardBody, CardFooter, CardGroup, Card } from "reactstrap";
import { GetAccessTokenSilentlyOptions, BillingPeriod, DetailedSubscription2, PaddleBillingType, PaddleSubscriptionPlan, User } from "../epicPenHelpers/epicpen_licencing_api";
import { buildInfo } from "../epicPenHelpers/build_info";
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from "react-ga4";
import LoadingSpinner from "./LoadingSpinner";
import { countryCodeToName, moneyToDisplayString } from "../epicPenHelpers/epicPenUtils";

export interface EPModalButton {
    text: string, onClick: () => void,
    loading: boolean,
    color: "red" | "blue"
}

interface EPModalProps {
    title : string
    isOpen: boolean
    close: () => void
    children: React.ReactNode
    button?: EPModalButton
}

const EPModal = (props: EPModalProps) => {

    return <Modal
        isOpen={props.isOpen}
        toggle={() => {
            props.close();
        }}
    >
        <div className="modal-header">
            <h5 className="modal-title mt-0" >
                {props.title}
            </h5>
            <button
                type="button"
                onClick={() => {
                    props.close();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            {props.children}
        </div>{props.button ?
        <div className="modal-footer">
                <button
                    type="button" className={`btn ${props.button.color === "red" ? "btn-danger" : "btn-primary"} w-md`} onClick={() => props.button?.onClick()}
                >
                    {props.button.loading ? <React.Fragment><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}</React.Fragment> : ""}{props.button.text}
            </button>
        </div> : []}
    </Modal>
}
export default EPModal;
