import React, { useState, useEffect, useMemo, FormEvent, useContext } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    Form,
    Label,
    Input,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Progress,
    CardFooter,
    Badge
} from "reactstrap";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ResetActivationCodeButton from "../../../epicPenComponents/ResetActivationCodeButton";
import DeviceIdTable, { DeviceIdDisplay } from "../../../epicPenComponents/tables/DeviceIdTable";
import { moneyToDisplayString } from "../../../epicPenHelpers/epicPenUtils";


import { Link, useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react';
import { buildInfo } from "../../../epicPenHelpers/build_info";
import { GetAccessTokenSilentlyOptions, Order, EpicPenContext } from "../../../epicPenHelpers/epicpen_licencing_api";

import { formatActivationCode } from "../../../epicPenHelpers/epicPenUtils";
import LoadingSpinner from "../../../epicPenComponents/LoadingSpinner";
import ActivationCodes from "../../ActivationCodes";
import ReactGA from "react-ga4";
import UserCard from "../../../epicPenComponents/UserCard";


interface Params {
    orderId: string
}





interface ActivationCodeCardContentProps {
    orderDetails: Order | null
    isLoading: boolean
}

const OrderCardContent = (props: ActivationCodeCardContentProps) => {


    const { locale } = useContext(EpicPenContext);

    return props.isLoading ? <LoadingSpinner /> :
        <React.Fragment>

            <div className="table-responsive">
                <table className="table table-nowrap mb-0">
                    <tbody>
                        <tr>
                            <th className="text-nowrap" scope="row">
                                Order id
                            </th>
                            <td colSpan={6}>{props.orderDetails?.id}</td>
                        </tr>
                        <tr>
                            <th className="text-nowrap" scope="row">
                                Cost
                            </th>
                            <td colSpan={6}>{moneyToDisplayString(props.orderDetails?.moneyAmount!, props.orderDetails?.currency ?? null)} {props.orderDetails?.status === "refunded" ? <Badge className="bg-danger">Refunded</Badge> : []}</td>
                        </tr>
                        <tr>
                            <th className="text-nowrap" scope="row">
                                Activation code
                            </th>
                            <td colSpan={6}>
                                <Link to={`/activationcodes/${props.orderDetails?.subscriptionId}`} className="has-arrow">View Activation Code</Link></td>
                        </tr>
                        <tr>
                            <th className="text-nowrap" scope="row">
                                Order date
                            </th>
                            <td colSpan={6}>{new Date(props.orderDetails?.orderDate!).toLocaleDateString(locale)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {
                props.orderDetails?.fastSpringOrderRef ?
                    <React.Fragment>
                        <div>
                            <a href={`https://licencing.epicpen.com/downloadOrderInvoice?orderId=${props.orderDetails.id}`} target="_blank" rel="noreferrer"><button
                                type="button"
                                className="btn btn-light float-end mt-3 ms-3"
                            >
                                Download invoice
                            </button>
                            </a>
                        </div>
                    </React.Fragment > : []
            }
            {
                props.orderDetails?.paddleReceiptURL ?
                    <React.Fragment>
                        <div>
                            <a href={props.orderDetails?.paddleReceiptURL} target="_blank" rel="noreferrer"><button
                                type="button"
                                className="btn btn-light float-end mt-3 ms-3"
                            >
                                View receipt
                            </button></a>
                        </div>
                    </React.Fragment > : []
            }
        </React.Fragment >
}

const OrderDetails = () => {
    const { orderId } = useParams<Params>();
    document.title = "Order | Epic Pen";
    const [loading, setLoading] = useState<boolean>(true);
    const [orderDetails, setOrderDetails] = useState<Order | null>(null);
    const { getAccessTokenSilently } = useAuth0();

    const getAccessToken = () => getAccessTokenSilently(GetAccessTokenSilentlyOptions);

    const { api, user } = useContext(EpicPenContext);

    useEffect(() => {
        const getDeviceIds = async () => {
            try {
                const orderDetails = await api.getOrder(orderId);
                setOrderDetails(() => orderDetails);
                setLoading(false)
            } catch (e: any) {
                console.log(`call api error: ${JSON.stringify(e)}`);
            }
        };
        getDeviceIds();
    }, []);



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={`Order`} breadcrumbItem={`Order`} />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <OrderCardContent orderDetails={orderDetails} isLoading={loading} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {
                        user.role == "admin" ? <Row>
                            <Col lg="12">


                                <Card className="border border-danger">
                                    <div className="card-header bg-transparent border-danger">
                                        <h5 className="my-0 text-danger">
                                            <i className="mdi mdi-alert-outline me-3"></i>Admin
                                        </h5>
                                    </div>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <p className="card-text">
                                                    {
                                                        orderDetails?.paddleOrderId && <a href={`https://vendors.paddle.com/orders/detail/${orderDetails?.paddleOrderId}`} target="_blank" rel="noreferrer" >View on Paddle</a>
                                                    }
                                            </p>

                                        </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : []
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default OrderDetails;