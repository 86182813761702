import React, { useState, useEffect, useMemo, FormEvent, MouseEventHandler, useContext } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    Form,
    Label,
    Input,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Progress,
    CardFooter,
    Modal,
    Alert,
    Badge
} from "reactstrap";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ResetActivationCodeButton from "../../../epicPenComponents/ResetActivationCodeButton";
import DeviceIdTable, { DeviceIdDisplay } from "../../../epicPenComponents/tables/DeviceIdTable";


import { Link, useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react';
import { buildInfo } from "../../../epicPenHelpers/build_info";
import { GetAccessTokenSilentlyOptions, ActivationCode, Subscription, DetailedSubscription2, BillingPeriod, PaddleBillingType, PaddleSubscriptionPlan, Subscription2, EpicPenContext, User } from "../../../epicPenHelpers/epicpen_licencing_api";

import { countryCodeToName, formatActivationCode, isTrialActive, nameof } from "../../../epicPenHelpers/epicPenUtils";
import LoadingSpinner from "../../../epicPenComponents/LoadingSpinner";
import SubscriptionCardContent from "../../../epicPenComponents/SubscriptionCardContent";
import ActivationCodes from "../";
import ReactGA from "react-ga4";
import OrderDetails from "../../Orders/OrderDetails";
import { boolean } from "yup/lib/locale";
import { moneyToDisplayString } from "../../../epicPenHelpers/epicPenUtils";
import { userInfo } from "os";
import OrderTable from "../../../epicPenComponents/tables/OrderTable";
import UserCard from "../../../epicPenComponents/UserCard";
import EPCard from "../../../epicPenComponents/EPCard";
import { head, mapValues, set } from "lodash";
import EPModal from "../../../epicPenComponents/EPModal";
//import { Date } from "../../pages/Invoices/invoicelistCol";


interface Params {
    subscription2id : string
}


interface DelegatedAdminFormProps {
    activationCodeDetails: Subscription2 | null
    isLoading: boolean
}


const DelegatedAdminForm = (props: DelegatedAdminFormProps) => {
    const [pendingSave, setPendingSave] = useState<boolean>(false);
    const { api } = useContext(EpicPenContext)

    const saveLicencedToName = async (e: FormEvent) => {
        ReactGA.event("ep_save_licenced_to_name_clicked");
        e.preventDefault();
        setPendingSave(true);

        const target = e.target as typeof e.target & {
            displayName: { value: string };
            adminEmail: { value: string };
        };

        await api.updateDelegatedAdmin(props.activationCodeDetails!.id, target.displayName.value, target.adminEmail.value)
        setPendingSave(false);
    };

    return < Form onSubmit={saveLicencedToName} ><Card><React.Fragment>
        <CardHeader>
            <h4 className="card-title">Delegated administration</h4>
            <p className="card-title-desc">
                Settings for helping share administration of this activation code with another party. Useful if you have bought this activation code for another party.</p>
        </CardHeader>
        {
            props.isLoading ? <CardBody><LoadingSpinner /></CardBody> : <React.Fragment><CardBody>
                <h6>"Licenced to" name</h6>
                <p className="card-text">
                    If you are managing this activation code for another party to use, enter that parties name here.
                </p>
                <div className="mb-3">
                    <Input
                        type="text"
                        className="form-control"
                        name="displayName"
                        placeholder="'Licenced to' name"
                        defaultValue={props.activationCodeDetails?.displayName ?? ""}
                    />
                </div>
                <h6>Alternative admin email</h6>
                <p className="card-text">
                    If you want another Epic Pen account to be able to view and administer this activation code, enter the email address of that account here.
                </p>
                <div className="mb-3">
                    <Input
                        type="text"
                        className="form-control"
                        name="adminEmail"
                        placeholder="joe@test.com"
                        defaultValue={props.activationCodeDetails?.alternativeAdminEmail ?? ""}
                    />
                </div>
            </CardBody>
                <CardFooter>
                    <button type="submit" className="btn btn-primary w-md float-end">
                        {pendingSave ? <React.Fragment><i className="bx bx-loader bx-spin font-size-16 align-middle me-2d"></i>{" "}Saving</React.Fragment> : <React.Fragment>Save</React.Fragment>}
                    </button>
                </CardFooter></React.Fragment>
        }</React.Fragment>
    </Card></Form>
}

interface ActivationCodeCardContentProps {
    activationCodeDetails: Subscription2 | null
    isLoading: boolean
}

const ActivationCodeCardContent = (props: ActivationCodeCardContentProps) => {
    const [showCopied, setShowCopied] = useState<boolean>(false);
    const [copyTimeout, setCopyTimeout] = useState<NodeJS.Timeout | null>(null);

    const copyToClipboard = (str: string) => {
        try {
            navigator.clipboard.writeText(str);
        } catch (e) {

        }
    };


    const handleCopyClick = () => {
        ReactGA.event("ep_copy_activation_code_clicked");
        if (copyTimeout !== null) {
            clearTimeout(copyTimeout);
        }
        copyToClipboard(formatActivationCode(props.activationCodeDetails!.activationCode));
        setShowCopied(true);

        setCopyTimeout(setTimeout(() => {
            setShowCopied(false);
        }, 1000));

    }

    return props.isLoading ? <LoadingSpinner /> : 
        <React.Fragment>
            <div className="input-group mb-3">
                <input type="text" className="form-control font-monospace font-weight-bold" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" value={formatActivationCode(props.activationCodeDetails!.activationCode)} readOnly />
                <div className="input-group-append">
                    <button className="btn btn-primary waves-effect waves-light" type="button" onClick={handleCopyClick}>{showCopied ? "copied" : "copy"}</button>
                </div>
            </div>
            <p className="mt-3">You have activated {props.activationCodeDetails!.deviceIdCount} out of a maximum of {props.activationCodeDetails!.numOfLicences} {props.activationCodeDetails!.numOfLicences > 1 ? "devices" : "device"}.</p>
            <Progress value={((Math.min(props.activationCodeDetails!.numOfLicences, props.activationCodeDetails!.deviceIdCount)) / (props.activationCodeDetails!.numOfLicences)) * 100} color="brand"></Progress>
            {
                 props.activationCodeDetails?.isPermenentModeTrialActivationCode ? <React.Fragment>
                    <h4 className="mt-3">Trial status</h4>
                    <p>
                        {
                            isTrialActive(props.activationCodeDetails) ?
                                <Badge className="bg-success">Active</Badge> :
                                <Badge className="bg-danger">Expired</Badge>
                        }
                    </p>
                </React.Fragment> : []
            }
            {props.activationCodeDetails?.permanentModeOrderId ? <div className="mt-4">
                <Link to={`/orders/${props.activationCodeDetails?.permanentModeOrderId}`}>

                    <button type="submit" className="btn btn-primary w-md">
                        View order
                    </button>
                </Link>
            </div> : []
            }
        </React.Fragment >
}

interface AdminActivationCodeDetailsCard {
    activationCode: Subscription2 | null
}

const AdminActivationCodeDetailsCard = (props: AdminActivationCodeDetailsCard) => {

    const { getAccessTokenSilently } = useAuth0();
    const { api } = useContext(EpicPenContext)
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const [resultModal, setResultModal] = React.useState<{ message: string, success: boolean } | null>(null);

    const [createLicenseFileModalModalVisible, setCreateLicenseFileModalModalVisible] = React.useState<boolean>(false);
    const [licenceFileNotes, setLicenceFileNotes] = React.useState<string>("");


    const [activationCodeUpdate, setActivationCodeUpdate] = useState<{ [index: string]: any }>({
        [nameof<Subscription2>("notes")]: "",
    })

    console.log(activationCodeUpdate)
    const subsmitActivationCodeUpdate = async () => {
        if (props.activationCode) {
            setSubmitting(true);
            try {
                const result = await api.patchSubscription(props.activationCode.id, activationCodeUpdate);
                if (result?.error) {
                    console.log(result?.error)
                    setResultModal({ message: "There was an error updating the activation code. Please try again later.", success: false });
                }
                setSubmitting(false);
            } catch (e) {
                setResultModal({ message: "There was an error updating the activation code. Please try again later.", success: false });
                setSubmitting(false);
            }
        }
    };


    useEffect(() => {
        if (props.activationCode) {
            setActivationCodeUpdate((oldActivationCodeUpdate) => mapValues(oldActivationCodeUpdate, (o, a, e) => (props.activationCode as any)[a]))
        }
    }, [props.activationCode]);

    const download = (filename : string, text : string) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    const createLicenseFile = async () => {
        const result = await api.createLicenseFile(props.activationCode!.id, licenceFileNotes);
        if (!(result?.error)) {
            download("licensefile.eplicense", JSON.stringify(result.body));
        }
    };

    return <EPCard title="Activation code details" danger={true}
        actionButtons={{ buttons: [{ header: "Create offline activation license file", action: () => { setCreateLicenseFileModalModalVisible(true) } }], danger: true }}
        primaryButton={{ header: isSubmitting ? "Saving..." : "Save", action: () => { subsmitActivationCodeUpdate() }, loading: isSubmitting, danger: true } }
    > {!props.activationCode ? <LoadingSpinner /> : <React.Fragment>
        <EPModal
            title={resultModal?.success ? "Success" : "Error"}
            isOpen={Boolean(resultModal)}
            close={() => setResultModal(null)}
            button={{
                text: "Okay",
                color: "blue",
                loading: false,
                onClick: () => setResultModal(null)
            }}
        >
            <div className="modal-body">
                <p>{resultModal?.message ?? ""}
                </p>
            </div>
        </EPModal>

            <EPModal
                title="Create offline activation license file"
                isOpen={createLicenseFileModalModalVisible}
                close={() => setCreateLicenseFileModalModalVisible(false)}
                button={{
                    text: "Create license file",
                    color: "blue",
                    loading: false,
                    onClick: () => { createLicenseFile(); setCreateLicenseFileModalModalVisible(false) }
                }}
            >
                <div className="modal-body">
                    <p>Enter any notes you want included in the licence file
                    </p>
                    <div className="mb-3">
                        <Input
                            type="text"
                            className="form-control"
                            defaultValue={licenceFileNotes}
                            onChange={(event: any) => { setLicenceFileNotes(((event.target as any).value)) }}
                        />
                    </div>
                </div>
            </EPModal>
        {
                props.activationCode?.paddleSubscriptionId ? <p className="card-text">
                    <a href={props.activationCode.paddleSubscriptionId.startsWith("sub_") ?`https://vendors.paddle.com/subscriptions-v2/${props.activationCode?.paddleSubscriptionId}` : `https://vendors.paddle.com/subscriptions/customers/manage/${props.activationCode?.paddleSubscriptionId}`} target="_blank" rel="noreferrer" >View subscription on Paddle</a>
            </p> : []
        }
        <div className="mb-3">
            <Label className="form-label">
                Referral code
            </Label>
                <p>{props.activationCode?.referralCode?.toString() ?? "None"}</p>
        </div>
        <div className="mb-3">
            <Label className="form-label">
                Activation code email sent successfully
            </Label>
                <p>{props.activationCode?.activationCodeEmailSentSuccessfully?.toString() ?? ""}</p>
        </div>
        <div className="mb-3">
            <Label className="form-label">
                User
            </Label>
                <p><Link to={`/profile/${props.activationCode?.userId}`}>{props.activationCode?.user?.email ?? ""}</Link></p>
        </div>
        <div className="mb-0">
            <Label className="form-label">
                Notes
            </Label>
            <Input
                type="text"
                className="form-control"
                name="company"
                placeholder="notes"
                    contentEditable={Boolean(props.activationCode)}
                    defaultValue={props.activationCode?.notes || ""}
                    maxLength={1000}
                    onChange={(event: any) => { setActivationCodeUpdate((activationCodeUpdate) => set(activationCodeUpdate, nameof<Subscription2>("notes"), ((event.target as any).value))) }}
            />
        </div>
    </React.Fragment>
                                            }</EPCard >
}

const ActivationCodeDetails = () => {
    const { subscription2id } = useParams<Params>();
    document.title = "Activation Code | Epic Pen";
    const [devicdeIds, setDeviceIds] = useState<DeviceIdDisplay[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [activationCodeDetails, setActivationCodeDetails] = useState<Subscription2 | null>(null);
    const { api } = useContext(EpicPenContext)

    const activationCodeDetailsAdjusted: Subscription2 | null = activationCodeDetails;

    useEffect(() => {
        const getDeviceIds = async () => {
            try {
                const activationCodeDetails = await api.getSubscription2(subscription2id);
                setActivationCodeDetails(() => activationCodeDetails);
                setLoading(false)
            } catch (e: any) {
                console.log(`call api error: ${JSON.stringify(e)}`);
            }
        };
        getDeviceIds();
    }, []);

    const resetCompleted = () => {
        setDeviceIds(() => []);
        setActivationCodeDetails((acDetails: Subscription2 | null) => {
            acDetails!.deviceIdCount = 0;
            return acDetails;
        });
    };

    const setDeviceIdsOnDeactivate = (reducer: (deviceIds: DeviceIdDisplay[]) => DeviceIdDisplay[]) => {

        setDeviceIds(reducer)
    }

    const showSubscriptionCard = Boolean(activationCodeDetails?.mode === "standard")

    const { user } = useContext(EpicPenContext);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={`Activation code`} breadcrumbItem={`Activation code`} />
                    <Row>
                        <Col lg={showSubscriptionCard ? 4 : 6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Activation code</h4>
                                </CardHeader>
                                <CardBody>
                                    <ActivationCodeCardContent activationCodeDetails={activationCodeDetailsAdjusted} isLoading={loading} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={showSubscriptionCard ? 4 : 6}>
                            {
                                activationCodeDetails?.activationCode ? <Card>
                                    <CardBody>
                                        <h4 className="card-title">Reset activation code</h4>
                                        <p className="card-text">
                                            Remove all devices from this activation code. Use this is you are moving your licences to new machines.
                                        </p>
                                        <ResetActivationCodeButton activationCode={activationCodeDetails?.activationCode} completed={resetCompleted} />
                                    </CardBody>
                                </Card> : []
                            }
                            {
                                user.role === "admin" ?
                                        <DelegatedAdminForm activationCodeDetails={activationCodeDetails} isLoading={loading} />
                                : []
                            }
                        </Col>

                        {showSubscriptionCard && activationCodeDetails?.id ? <Col lg="4">
                            <Card>
                                    <SubscriptionCardContent activationCodeId={activationCodeDetails?.id!} setNewNumOfLicences={(numOfLicences) => setActivationCodeDetails((acd) => { acd!.numOfLicences = numOfLicences; return acd; })} />
                            </Card>
                        </Col> : [] }
                        
                    </Row>

                    {
                        user.role == "admin" ? <Row>
                            <Col lg="12">
                                <Card className="border border-danger">
                                    <div className="card-header bg-transparent border-danger">
                                        <h5 className="my-0 text-danger">
                                            <i className="mdi mdi-alert-outline me-3"></i>Admin
                                        </h5>
                                    </div>
                                    <CardBody>
                                        <Row><Col sm={12} md={6}>
                                            <AdminActivationCodeDetailsCard activationCode={activationCodeDetails} />
                                        </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : []
                    }
                    <Row>

                        <Col lg="6">
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Activated Devices</h4>
                                </CardHeader>
                                <CardBody>
                                    {activationCodeDetails?.activationCode ? <DeviceIdTable activationCode={activationCodeDetails.activationCode} /> : [] }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Orders</h4>
                                </CardHeader>
                                <CardBody>
                                    {activationCodeDetails ?
                                        <OrderTable currentUserOnly={false} subscription2id={activationCodeDetails.id} /> : []
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ActivationCodeDetails;