type Environment =
    "production" |
    "development" |
    "developmentmachine"

interface BuildInfo {
    gitCommitHash: string
    buildDate: string
    environment: Environment
    isProduction: boolean
    isDevelopment: boolean
    isDevMachine: boolean
}

const buildInfoTemp: BuildInfo = require('./../generatedBuildInfo.json');
buildInfoTemp.isDevelopment = buildInfoTemp.environment !== "production"
buildInfoTemp.isDevMachine = buildInfoTemp.environment === "developmentmachine"
buildInfoTemp.isProduction = buildInfoTemp.environment === "production"


export const buildInfo: BuildInfo = buildInfoTemp;
