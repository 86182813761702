import React, { useState, useEffect, useMemo, FormEvent, useContext } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    Form,
    Label,
    Input,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Progress,
    CardFooter,
    Badge
} from "reactstrap";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ResetActivationCodeButton from "../../../epicPenComponents/ResetActivationCodeButton";
import DeviceIdTable, { DeviceIdDisplay } from "../../../epicPenComponents/tables/DeviceIdTable";
import { CopyButton, moneyToDisplayString, nameof } from "../../../epicPenHelpers/epicPenUtils";


import { Link, useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react';
import { buildInfo } from "../../../epicPenHelpers/build_info";
import { GetAccessTokenSilentlyOptions, Order, EpicPenContext, CustomOrder } from "../../../epicPenHelpers/epicpen_licencing_api";

import { formatActivationCode } from "../../../epicPenHelpers/epicPenUtils";
import LoadingSpinner from "../../../epicPenComponents/LoadingSpinner";
import ActivationCodes from "../../ActivationCodes";
import ReactGA from "react-ga4";
import UserCard from "../../../epicPenComponents/UserCard";
import EPCard from "../../../epicPenComponents/EPCard";
import EPModal from "../../../epicPenComponents/EPModal";
import { set } from "lodash";
import cc from "currency-codes";






interface ActivationCodeCardContentProps {
    customOrder: CustomOrder | null
    isLoading: boolean
}

const CustomOrderCardContent = (props: ActivationCodeCardContentProps) => {


    const { locale, api } = useContext(EpicPenContext);


    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [resultModal, setResultModal] = React.useState<{ message: string, success: boolean } | null>(null);

    const [customOrderUpdate, setCustomOrder] = useState<{ [index: string]: any }>({
        [nameof<CustomOrder>("notes")]: "",
    })

    console.log(customOrderUpdate)
    const submitCustomOrder = async () => {
        if (props.customOrder) {
            setSubmitting(true);
            try {
                const result = await api.patchCustomOrder(props.customOrder.id, customOrderUpdate);
                if (result?.error) {
                    console.log(result?.error)
                    setResultModal({ message: "There was an error updating the custom order. Please try again later.", success: false });
                }
                setSubmitting(false);
            } catch (e) {
                setResultModal({ message: "There was an error updating the custom order. Please try again later.", success: false });
                setSubmitting(false);
            }
        }
    };


    const deleteCustomOrder = async () => {
        if (props.customOrder) {
            setSubmitting(true);
            try {
                const result = await api.deleteCustomOrder(props.customOrder.id);
                if (result?.error) {
                    console.log(result?.error)
                    setResultModal({ message: "There was an error deleting the custom order. Please try again later.", success: false });
                }
                setSubmitting(false);
                setResultModal({ message: "The custom order was deleted successfully", success: false });
            } catch (e) {
                setResultModal({ message: "There was an error deleting the custom order. Please try again later.", success: false });
                setSubmitting(false);
            }
        }
    };


    const webLink = `https://epicpen.com/subscribe/?customOrderId=${props.customOrder?.id}`;
    return <EPCard
        primaryButton={
            {
                header: isSubmitting ? "Saving..." : "Save",
                action: () => { submitCustomOrder() },
                loading: isSubmitting,
                danger: true
            }}

        actionButtons={
            {
                buttons: [{
                    header: "Delete",
                    action: () => { deleteCustomOrder() }
                }],
                danger: true
            }}
        title="Custom order"
    > {
        props.isLoading ? <LoadingSpinner /> : <React.Fragment>
            <EPModal
            title={resultModal?.success ? "Success" : "Error"}
            isOpen={Boolean(resultModal)}
            close={() => setResultModal(null)}
            button={{
                text: "Okay",
                color: "blue",
                loading: false,
                onClick: () => setResultModal(null)
            }}
        >
            <div className="modal-body">
                <p>{resultModal?.message ?? ""}
                </p>
            </div>
        </EPModal>
            <div className="mb-3">
                <Label className="form-label">
                    Percentage discount
                </Label>
                <p>-%{props.customOrder?.percentageDiscount}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Billing period
                </Label>
                <p>{props.customOrder?.billingPeriod ?? ""}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Number of licences
                </Label>
                <p>{props.customOrder?.numOfLicences ?? ""}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Billing period
                </Label>
                <p>{props.customOrder?.billingPeriod ?? ""}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    End user name
                </Label>
                <p>{props.customOrder?.endUserName ?? ""}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    End user email
                </Label>
                <p>{props.customOrder?.endUserEmail ?? ""}&nbsp;<CopyButton value={props.customOrder?.endUserEmail ?? ""} /></p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Payment user email
                </Label>
                <p>{props.customOrder?.paymentUserEmail ?? ""}&nbsp;<CopyButton value={props.customOrder?.paymentUserEmail ?? ""} /></p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Payment link
                </Label>
                <p><a href={webLink} target="_blank" rel="noreferrer" >{webLink}</a>&nbsp;<CopyButton value={webLink} /></p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Coupon code
                </Label>
                <p>{props.customOrder?.paddleCouponCode ?? ""}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    PO number
                </Label>
                <p>{props.customOrder?.PONumber ?? ""}</p>
            </div>
            <div className="mb-3">
                <Label className="form-label">
                    Fulfilled
                </Label>
                    <p>{props.customOrder?.fulfilled ? <Badge className="bg-success">Fulfilled</Badge> : <Badge className="bg-warning">Pending</Badge>}</p>
            </div>
            <div className="mb-0">
                <Label className="form-label">
                    Notes
                </Label>
                <Input
                    type="text"
                    className="form-control"
                    placeholder="notes"
                    contentEditable={!props.isLoading}
                    defaultValue={props.customOrder?.notes || ""}
                    onChange={(event: any) => { setCustomOrder((customOrderUpdate) => set(customOrderUpdate, nameof<CustomOrder>("notes"), ((event.target as any).value))) }}
                    maxLength={1000}
                />
            </div>


        </React.Fragment>
    }</EPCard>;

}

interface Params {
    customOrderId: string
}

const CustomOrderDetails = () => {
    const { customOrderId } = useParams<Params>();
    document.title = "Custom Order | Epic Pen";
    const [loading, setLoading] = useState<boolean>(true);
    const [customOrderDetails, setCustomOrderDetails] = useState<CustomOrder | null>(null);

    const { api } = useContext(EpicPenContext);

    useEffect(() => {
        const getDeviceIds = async () => {
            try {
                const customOrderDetails = await api.getCustomOrder(customOrderId);
                setCustomOrderDetails(() => customOrderDetails);
                setLoading(false)
            } catch (e: any) {
                console.log(`call api error: ${JSON.stringify(e)}`);
            }
        };
        getDeviceIds();
    }, []);



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={`Custom order`} breadcrumbItem={`Custom order`} />
                    <Row>
                        <Col lg="12">
                            <CustomOrderCardContent customOrder={customOrderDetails} isLoading={loading} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CustomOrderDetails;