import React, { useState, useEffect, useMemo, useContext } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Input,
    CardFooter
} from "reactstrap";

import { getInvoices as onGetInvoices } from "../../store/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import $ from 'jquery';

import { Link, useParams } from "react-router-dom";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react';
import { buildInfo } from "../../epicPenHelpers/build_info";
import ActivationCodeTable from "../../epicPenComponents/tables/ActivationCodeTable";
import { User, BillingPeriod, EpicPenContext } from "../../epicPenHelpers/epicpen_licencing_api";

import { paddleNums, PaddleMode, moneyToDisplayString } from "../../epicPenHelpers/epicPenUtils";
import LoadingSpinner from "../../epicPenComponents/LoadingSpinner";

interface Params {
    activationCode : string
}

interface ActivationCodeDisplay {
    id: string
    idFormatted?: string
    maxDevices: number
    deviceIdCount: number
}

interface PaddlePrice {
    "gross": number,
    "net": number,
    "tax": number
}

interface PaddleProduct {
    "product_id": number,
    "product_title": string,
    "currency": string,
    "vendor_set_prices_included_tax": boolean,
    "price": PaddlePrice,
    "list_price": PaddlePrice,
    "subscription": {
        "trial_days": number,
        "interval": string,
        "frequency": number,
        "price": PaddlePrice,
        "list_price": PaddlePrice
    }
}

interface PaddleProducts {
    "customer_country": string
    "products": PaddleProduct[]
}



interface PaddleResponse<T> {
    success: boolean
    response : T
}

interface ActivationCodesProps {
    currentUserOnly: boolean
    adminMode?: boolean
}


const ActivationCodes = (props : ActivationCodesProps) => {
    document.title = "Activation Codes | Epic Pen";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="Activation Codes" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <ActivationCodeTable currentUserOnly={props.currentUserOnly} adminMode={props.adminMode} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ActivationCodes;