import React, { createContext, useEffect, useMemo, useState } from "react";

import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";



var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: 'ba8ffd90-1a0c-4200-a4c3-ff3d7002cbf1',
        enableAutoRouteTracking: true,
        extensions: [reactPlugin as unknown as ITelemetryPlugin]
    }
});

appInsights.addTelemetryInitializer((envelope) => {
    envelope.data!.gitSHA = buildInfo.gitCommitHash;
});

if (!buildInfo.isDevMachine) {
    appInsights.loadAppInsights();
}
import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useSelector } from "react-redux";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend";

import ReactDOM from 'react-dom';
import { Auth0Provider, useAuth0, User, withAuthenticationRequired } from '@auth0/auth0-react';

import { buildInfo } from "./epicPenHelpers/build_info";
import { PaddleMode, paddleNums } from "./epicPenHelpers/epicPenUtils";

import ReactGA from "react-ga4";
import { User as EPUser, EpicPenContext, GetAccessTokenSilentlyOptions, createEpicPenLicencingAPI } from "./epicPenHelpers/epicpen_licencing_api";
ReactGA.initialize("G-YFF0CK2NJS");
ReactGA.send("pageview");

declare var profitwell : any;

const Paddle = (window as any).Paddle;
Paddle.Setup({
    debug: !buildInfo.isProduction,
    seller: buildInfo.isProduction ? paddleNums.production.vendorId : paddleNums.sandbox.vendorId,
    pwAuth: "0b91a365fd3117519fd8916d3b8713aa",
});

if (!buildInfo.isProduction) {
    Paddle.Environment.set('sandbox');
}

//api config
// import config from "./config";

// Activating fake backend
fakeBackend();



const App = () => {

    const MainContents = () => {
        const { layoutType } = useSelector((state: any) => ({
            layoutType: state.Layout.layoutType,
        }));


        const [epUser, setEpicUser] = useState<EPUser | null>(null);
        const { user, getAccessTokenSilently, loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
        const getAccessTokenSilentlyWithOptions = () => getAccessTokenSilently(GetAccessTokenSilentlyOptions)
        ReactGA.set({ userId: user?.email });

        const epicPenLicencingAPI = useMemo(() => user || buildInfo.isDevMachine ? createEpicPenLicencingAPI(getAccessTokenSilentlyWithOptions, buildInfo.isDevMachine) : null, [user, getAccessTokenSilently]);
        useEffect(() => {
            const getEPUserLocal = async () => {
                if (epicPenLicencingAPI) {
                    const user = (await epicPenLicencingAPI.getCurrentUser())
                    setEpicUser(() => user);
                }
            }
            getEPUserLocal()
        }, [epicPenLicencingAPI])

        useEffect(() => {
            if (!isLoading && !isAuthenticated) {
                loginWithRedirect();
            }
        }, [isLoading, isAuthenticated])

        useEffect(() => {
            if (epUser) {
                profitwell('start', { 'user_id': epUser.id});
            }
        }, [epUser])

        function getLayout() {
            let layoutCls: Object = VerticalLayout;
            switch (layoutType) {
                case "horizontal":
                    layoutCls = HorizontalLayout;
                    break;
                default:
                    layoutCls = VerticalLayout;
                    break;
            }
            return layoutCls;
        }

        const locale = (() => {
            try {
                const locales = Intl.Collator.supportedLocalesOf(`en-${epUser?.countryCode ?? "US"}`)
                return locales.length > 0 ? locales[0] : "en-US";
            } catch (e) {
                return "en-US";
            }
        })()

        const Layout = getLayout();
        return (EpicPenContext && epUser && epicPenLicencingAPI) ? <EpicPenContext.Provider value={{ user: epUser, api: epicPenLicencingAPI, locale: locale }}>
            <React.Fragment>{
                <Router>
                    <Switch>
                        {authRoutes.map((route, idx) => (
                            <Authmiddleware
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                                exact
                            />
                        ))}

                        {userRoutes.map((route: any, idx: number) => {
                            const middleware =
                                <Authmiddleware
                                    path={route.path}
                                    layout={Layout}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={true}
                                    exact
                                />;
                            return middleware;
                        })}
                    </Switch>
                </Router>
            }
            </React.Fragment>
        </EpicPenContext.Provider> : <React.Fragment/>;
    }

    const useProductionTenant = true;
    return (buildInfo.isDevMachine) ? <MainContents /> :
        <Auth0Provider
            domain={`${useProductionTenant ? "" : "dev."}auth.epicpen.com`}// "dev-h76-viz2.eu.auth0.com"
            clientId={useProductionTenant ? "g2QlbxdBa9WKjhyS2dPZcaVTAfxjADv2" : "brtBtel2fbShRzuI9apGobBcWr0uc8zv"}
            redirectUri={`https://${buildInfo.isProduction ? "" : "dev."}accounts.epicpen.com/dashboard`}
            //redirectUri={window.location.href}
        >
            <MainContents />
        </Auth0Provider>
    ;
};

export default App;