import { isNumber, random } from "lodash";
import React, { useState, useEffect, useMemo, useContext, createContext } from "react";
import { boolean, number } from "yup";
import { create } from "yup/lib/number";
import { buildInfo } from "../epicPenHelpers/build_info";
import { createGUID } from "./epicPenUtils";

const serverUrl = "https://licencing.epicpen.com/"

export interface User {
    id: string
    auth0id: string
    lastName: string
    firstName: string
    username?: string
    company: string
    countryCode: string | null
    email: string
    changeRequestEmail : string | null
    registerDate: string
    paddleCustomerId?: string
    notes: string | null
    testMode: boolean
    userPermissionGrantedForEmailMarketing: boolean
    tapfiliateReferralCode: string | null
    mixPanelDistinctId: string | null
    emailVerified: boolean
    refCode: string | null
    role: "admin" | "standard"
    emailVerificationToken: string | null
    emailVerificationEmailStatus: EmailSendStatus
    emailChangeVerificationEmailStatus: EmailSendStatus
    emailVerificationEmailSendGridId: string | null
    deleted: boolean
}

export interface Order {
    PONumber: string
    coupons: string
    currency: string
    payoutCurrency: string
    displayName: string
    fastSpringOrderRef: string
    paddleReceiptURL: string
    paddleOrderId: string
    fastspringProductPath: string
    fastspringTotalUSDPayout: string
    howIUse: string
    id: string
    individualActivationCodes: boolean
    licenceType: string
    licenceeId: string
    moneyAmount: number
    payoutMoneyAmount: number
    notes: string
    numOfLicences: number
    orderDate: string
    orderNum: number
    stackCommerceTransactionId: string
    stripeId: string
    mode: "subscription" | "onceoff"
    status: "active" | "refunded"
    userId: string | null
    subscriptionId: string | null
    user: User
}

export type BillingPeriod = "monthly" | "yearly";

export interface Subscription {
    id: string
    userId: string
    creationDate: string
    notes: string
    currency: string
    price: number
    cancelURL: string
    updateURL: string
    paddleCheckoutId: string
    paddleSubscriptionId: string
    paddleSubscriptionPlanId: string
    numOfLicences: number
    billingPeriod: BillingPeriod
    nextBillDate: string | null
    testMode: boolean
    status: "active" | "trialing" | "pastdue" | "paused" | "deleted"
}

export interface Payment {
    amount: number
    currency: string
    date: string
}

export interface PaymentInformation {
    payment_method: string
    card_type: string
    last_four_digits: string
    expiry_date: string
}

export interface PaddleSubscription {
    subscription_id: number
    plan_id: number
    user_id: number
    user_email: string
    marketing_consent: boolean
    update_url: string
    cancel_url: string
    state: "active" | "past_due" | "trialing" | "paused"
    signup_date: string
    last_payment: Payment
    payment_information: PaymentInformation
    quantity: number
    next_payment: Payment
}
export interface PaddleBillingSubscription {
    items: [
        {
            quantity: number
            next_billed_at: string
        }
    ]
    management_urls: {
        update_payment_method: string
        cancel:  string
    },
    id : string,
    customer_id : string
}

export type PaddleBillingType = "day" | "month" | "year"

export interface PaddleSubscriptionPlan {
    id: number
    name: string
    billing_type: PaddleBillingType
    billing_period: number
    initial_price: {
        [index: string]: string;
    }
    recurring_price: {
        [index: string]: string;
    }
    trial_days: number
}


export type LineItem = {
    price: {
        id: string
        unit_price: {
            currency_code: string
        }
    }
    formatted_unit_totals: {
        total: string
    }
    formatted_totals: {
        total: string
    }
    totals: {
        total: string
    }
    discounts: [{
        discount: {
            code : string
            type: "percentage" | "flat"
            amount: string
        }
    }]
}

export type PricingPreview = {
    currency_code: string,
    details: {
        line_items: LineItem[]
    }
}

export type DetailedSubscription2 = {
    usingPaddlingBilling: false
    subscription: Subscription2
    paddleSubscription: PaddleSubscription
    paddleSubscriptionPlans: PaddleSubscriptionPlan[]
} | {
    usingPaddlingBilling: true
    subscription: Subscription2
    paddleBillingSubcription:  PaddleBillingSubscription
    previewLineItem: LineItem
    currencyCode : string
}

export interface DeviceId {
    id: string
    activationCode: string
    osType: string
    activationDate: string
    activatedByGitSHA: string
    createdByEpicPenVersion: string
    lastVerifiedByEpicPenVersion: string
    signedHash: string
    creationDate: string
    createdByGitSHA: string
    lastVerificationDate: string
    lastVerifiedByGitSHA: string
    isTrialMode: string
    friendlyName: string
}

export interface ActivationCode {
    id: string
    maxDevices: number
    deviceIdCount: number
    displayName: string | null
    orderId: string | null
    subscriptionId?: string | null
    alternativeAdminEmail?: string
}

export type EmailSendStatus = "unsent" | "processed" | "dropped" | "delivered" | "deferred" | "bounce" | "blocked"

export interface Subscription2 {
    id: string
    userId: string
    creationDate: string
    notes: string
    currency: string
    price: number
    cancelURL: string
    updateURL: string
    paddleCheckoutId: string
    paddleSubscriptionId: string
    paddleSubscriptionPlanId: string
    numOfLicences: number
    status: "active" | "trialing" | "pastdue" | "paused" | "deleted" | "pendingCancelation"
    permanentModeStatus: "active" | "refunded" | null
    referralCode: string | null
    billingPeriod: BillingPeriod
    nextBillDate: string
    nextPaymentAmount: number
    testMode: boolean
    activationCodeEmailSentSuccessfully: boolean
    activationCodeEmailSendGridId: string | null

    activationCode: string
    mode: "standard" | "permanent"
    activationCodeEnabled: boolean
    permanentModeOrderId: string | null
    isPermenentModeTrialActivationCode: boolean
    permanentModeTrialLengthInDays: number
    firstActivation: string | null
    displayName: string
    deviceIdCount: number
    alternativeAdminEmail: string | null
    user: User
    deleted: boolean
    cancelationScheduledDate: string | null
}

export interface Subscription2Upload {
    userId: string
    notes: string
    numOfLicences: number
    activationCode: string
    displayName: string
    alternativeAdminEmail: string | null
}

export type CustomOrderMode = "standard" | "invoice"
export type CustomOrderCurrency = "usd" | "eur" | "gbp"

export interface CustomOrder {
    id: string
    userId: string
    mode: CustomOrderMode
    currency: CustomOrderCurrency
    billingPeriod: BillingPeriod
    percentageDiscount: number
    recurring: boolean
    numOfLicences: number
    endUserName: string
    endUserEmail: string
    paymentUserEmail: string
    notes: string
    paddleCouponCode: string
    PONumber: string
    fulfilled: boolean
    subscriptionId : string | null
}



export interface LicenceDetails {
    totalDeviceLicences: number
    activatedDevices: number
}


export interface APIRequestResponse<T> {
    body?: T
    error?: { errorCode : string}
}

export interface PaginatedAPIResponse<T> {
    totalItemCount: number
    items: T[]
}

const GetAccessTokenSilentlyOptions = {
    audience: true ? `https://licencing.epicpen.com/` : `https://licencing.epicpen.com`,
    scope: "read:current_user"
}


const createAPIRequestInternal = async <T>(path: string, getAccessTokenSilently: () => Promise<string>, method?: string, queryParams?: any, body?: any, pageIndex?: number, pageSize?: number, searchString?: string, abortController?: AbortController) => {

    //const { getAccessTokenSilently } = useAuth0();

    //const getAccessTokenResponse = await getAccessTokenSilently({
    //    audience: `https://licencing.epicpen.com`,
    //    scope: "read:current_user"
    //});
    const accessToken = await getAccessTokenSilently()

    //const responseTest = await fetch(`${serverUrl}auth0TestMethod`, {
    //    method: method || "GET",
    //    headers: {
    //        Authorization: `Bearer ${getAccessTokenResponse}`,
    //    },
    //});
    //console.log(`responseTest: ${await responseTest.text()}`)
    const paginatedRequest = isNumber(pageIndex) && isNumber(pageSize);
    const query = "?" + (paginatedRequest ? [`limit=${pageSize}`, `offset=${pageIndex * pageSize}`, `search=${searchString ?? ""}`] : []).concat(queryParams ? Object.keys(queryParams).filter((key) => queryParams[key] !== undefined).map(key => `${key}=${queryParams[key]}`) : []).join("&");

    const response = await fetch(`${serverUrl}${path}${query}`, {
        method: method || "GET",
        body: body ? JSON.stringify(body) : null,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": 'application/json'
        },
        signal: abortController?.signal
    });


    return response
}


const createAPIRequest = async <T>(path: string, getAccessTokenSilently: () => Promise<string>, method?: string, queryParams?: any, body?: any, abortController?: AbortController) => {
    const response = await createAPIRequestInternal(path, getAccessTokenSilently, method, queryParams, body, undefined, undefined, undefined, abortController)
    const jsonResponse = response.headers.has("content-type") ? response.headers.get("content-type")!.indexOf("application/json") !== -1 : false;

    const result = response.ok ? <APIRequestResponse<T>>{ body: jsonResponse ? await response.json() : undefined } : <APIRequestResponse<T>>{ error: <{ errorCode: string }>(await response.json()) }
    if (buildInfo.isDevelopment)
        console.log({
            name: "createAPIRequest",
            path,
            queryParams,
            response,
            jsonResponse,
            result
        });
    return result;
}



const createPaginatedAPIRequest = async<T>(path: string, getAccessTokenSilently: () => Promise<string>, method ?: string, queryParams? : any, body?: any, pageIndex?: number, pageSize?: number, searchString?: string, abortController?: AbortController) => {
    const response = await createAPIRequestInternal(path, getAccessTokenSilently, method, queryParams, body, pageIndex, pageSize, searchString, abortController)
    const itemCount = response.headers.get("x-item-count");
    return {
        totalItemCount: parseInt(itemCount!),
        items: <T[]>(await response.json())
    } as PaginatedAPIResponse<T>;
}


const createFakeDataRequest = async <T>(responseData: T, abortController?: AbortController) => {
    const data = await createFakeDataResponse(responseData, abortController)
    return <APIRequestResponse<T>>{ body: data };
}

const createFakeDataResponse = async <T>(responseData: T, abortController?: AbortController) => {
    await new Promise((resolve, reject) => {
        abortController?.signal.addEventListener("abort", ((ev) => { reject(); }));
        setTimeout(resolve, 600 + 400 * Math.random());
    });
    return responseData;
}

const createPaginatedFakeDataResponse = async <T>(responseData: T[], abortController?: AbortController) => {
    await new Promise((resolve, reject) => {
        abortController?.signal.addEventListener("abort", ((ev) => { reject(); }));
        setTimeout(resolve, 600 + 400 * Math.random());
    });
    return {
        totalItemCount: 200,
        items: responseData
    } as PaginatedAPIResponse<T>;
}

const createFakeActivationCode = () => Array.from(Array(4 * 6).keys()).map(() => Math.floor(Math.random() * 16).toString(16)).join("")

const createFakeUser = (i? : number) => {
    return <User>{
        id: createGUID(),
        email: "fake@icme.ie",
        firstName: "Joe",
        lastName: "Smith",
        company: "ICME",
        role: "admin",
        registerDate: new Date().toISOString(),
        countryCode: "IE",
        emailVerified: (i ?? 0) % 2 === 0,
        auth0id: createGUID(),
        notes: "",
        testMode: false,
        userPermissionGrantedForEmailMarketing: true,
        tapfiliateReferralCode: "",
        mixPanelDistinctId: "",
        refCode: "",
        emailVerificationToken: null,
        emailVerificationEmailStatus: "unsent",
        emailVerificationEmailSendGridId: createGUID(),
        deleted: false
    };
}







const createFakeCustomerOrder = (): CustomOrder => {
    return {
        id: createGUID(),
        userId: createGUID(),
        billingPeriod: "monthly",
        mode: "standard",
        currency: "usd",
        percentageDiscount: 20,
        recurring: true,
        numOfLicences: 3,
        endUserName: "End User",
        endUserEmail: "endUser@icme.ie",
        paymentUserEmail: "purchases@icme.ie",
        notes: "test",
        paddleCouponCode: createGUID(),
        PONumber: createGUID(),
        fulfilled: false,
        subscriptionId: null
    };
}




const createEpicPenLicencingAPI = (getAccessTokenSilently: () => Promise<string>, useFakeData: boolean) => {

    return {
        //
        //   Users
        //

        getUsers : (pageIndex: number, pageSize: number, searchString?: string, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<User>(Array.from(Array(pageSize).keys()).map((i) => createFakeUser(i)
                ), abortController);
            } else {
                return createPaginatedAPIRequest<User>(`user`, getAccessTokenSilently, undefined, undefined, undefined, pageIndex, pageSize, searchString, abortController);
            }
        },

        getCurrentUser : async () => {
            if (useFakeData) {
                return createFakeDataResponse<User>(createFakeUser());
            } else {
                return (await createAPIRequest<User>("user/current", getAccessTokenSilently)).body!;
            }
        },


        getUser: async (userId: string) => {
            if (useFakeData) {
                return createFakeDataResponse<User>(createFakeUser());
            } else {
                return (await createAPIRequest<User>(`user/${userId}`, getAccessTokenSilently)).body!;
            }
        },


        closeUserAccount : async (userId: string) => {
            if (useFakeData) {
                await new Promise((resolve, reject) => {
                    setTimeout(resolve, 600 + 400 * Math.random());
                });
                return <APIRequestResponse<void>>{};
            } else {
                return await createAPIRequest<void>(`closeaccount`, getAccessTokenSilently, "POST", { userId });
            }
        },

        changeUserEmail: async (userId: string, email : string) => {
            if (useFakeData) {
                await new Promise((resolve, reject) => {
                    setTimeout(resolve, 600 + 400 * Math.random());
                });
                return <APIRequestResponse<void>>{};
            } else {
                return await createAPIRequest<void>(`user/${userId}/changeEmail`, getAccessTokenSilently, "POST", undefined, { email });
            }
        },


        patchUser: (userId: string, body: { [index: string]: any }) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`user/${userId}`, getAccessTokenSilently, "PATCH", undefined, body);
            }
        },


        resendEmailVerificationEmail: (userId: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`user/${userId}/resendemailverificationemail`, getAccessTokenSilently, "POST", undefined, { redirectURL : "https://epicpen.com" });
            }
        },

        requestPasswordReset: (userId: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`user/${userId}/requestPasswordReset`, getAccessTokenSilently, "POST");
            }
        },

        //
        //   Orders
        //


        getOrders : (pageIndex: number, pageSize: number, searchString?: string, currentUserOnly?: boolean, subscription2Id?: string, userId?: string, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<Order>(Array.from(Array(pageSize).keys()).map((i) => {
                    return {
                        id: createGUID(),
                        PONumber: "",
                        coupons: "",
                        currency: "USD",
                        payoutCurrency: "EUR",
                        displayName: "",
                        fastSpringOrderRef: createFakeActivationCode(),
                        paddleReceiptURL: "https://epicpen.com",
                        paddleOrderId: "000-000",
                        fastspringProductPath: "",
                        fastspringTotalUSDPayout: "",
                        howIUse: "",
                        individualActivationCodes: false,
                        licenceType: "",
                        licenceeId: "",
                        moneyAmount: 20.0,
                        payoutMoneyAmount: 15.0,
                        notes: "",
                        numOfLicences: 5,
                        orderDate: new Date().toISOString(),
                        orderNum: 101,
                        stackCommerceTransactionId: "",
                        stripeId: "",
                        mode: "subscription",
                        status: i % 2 === 0 ? "active" : "refunded",
                        userId: createGUID(),
                        subscriptionId: createGUID(),
                        user: createFakeUser()
                    }
                }
                ), abortController);
            } else {
                return createPaginatedAPIRequest<Order>(`order${currentUserOnly ? "/ofcurrentuser" : ""}`, getAccessTokenSilently, undefined, { subscription2Id, userId }, undefined, pageIndex, pageSize, searchString, abortController);
            }
        },


getOrder : async (orderId: string) => {
            if (useFakeData) {
                return await createFakeDataResponse<Order>({
                    id: createGUID(),
                    PONumber: "",
                    coupons: "",
                    currency: "EUR",
                    payoutCurrency: "EUR",
                    displayName: "",
                    fastSpringOrderRef: createFakeActivationCode(),
                    paddleReceiptURL: "https://epicpen.com",
                    paddleOrderId: "000-000",
                    fastspringProductPath: "",
                    fastspringTotalUSDPayout: "",
                    howIUse: "",
                    individualActivationCodes: false,
                    licenceType: "",
                    licenceeId: "",
                    moneyAmount: 20.0,
                    payoutMoneyAmount: 20.0,
                    notes: "",
                    numOfLicences: 5,
                    orderDate: new Date().toISOString(),
                    orderNum: 101,
                    stackCommerceTransactionId: "",
                    stripeId: "",
                    mode: "subscription",
                    status: "active",
                    userId: createGUID(),
                    subscriptionId: createGUID(),
                    user: createFakeUser()
                });
            } else {
                return (await createAPIRequest<Order>(`order/${orderId}`, getAccessTokenSilently)).body!;
            }
        },

        //
        //   Subscription2
        //


        getDetailedSubscription2: async (subscription2Id: string) => {
            if (useFakeData) {
                return await createFakeDataResponse<DetailedSubscription2>({
                    usingPaddlingBilling: false,
                    subscription: {
                        id: createGUID(),
                        userId: createGUID(),
                        creationDate: "2022-09-11",
                        notes: "",
                        currency: "EUR",
                        price: 20.0,
                        cancelURL: "https://epicpen.com",
                        updateURL: "https://epicpen.com",
                        paddleCheckoutId: createFakeActivationCode(),
                        paddleSubscriptionId: createFakeActivationCode(),
                        paddleSubscriptionPlanId: createFakeActivationCode(),
                        numOfLicences: Math.floor(Math.random() * 30),
                        billingPeriod: "yearly",
                        nextBillDate: "2022-09-1",
                        nextPaymentAmount: 24,
                        testMode: true,
                        status: "active",
                        permanentModeStatus: "active",
                        activationCode: createFakeActivationCode(),
                        deviceIdCount: Math.floor(Math.random() * 30),
                        displayName: "Fake display name",
                        permanentModeOrderId: null,
                        activationCodeEnabled: true,
                        deleted: false,
                        mode: "standard",
                        referralCode: null,
                        isPermenentModeTrialActivationCode: false,
                        permanentModeTrialLengthInDays: 0,
                        firstActivation: null,
                        alternativeAdminEmail: null,
                        user: createFakeUser(),
                        activationCodeEmailSentSuccessfully: false,
                        activationCodeEmailSendGridId: null,
                        cancelationScheduledDate: null
                    },
                    paddleSubscription: {
                        "subscription_id": 502198,
                        "plan_id": 496199,
                        "user_id": 285846,
                        "user_email": "name@example.com",
                        "marketing_consent": true,
                        "update_url": "https://subscription-management.paddle.com/subscription/87654321/hash/eyJpdiI6IlU0Nk5cL1JZeHQyTXd.../update",
                        "cancel_url": "https://subscription-management.paddle.com/subscription/87654321/hash/eyJpdiI6IlU0Nk5cL1JZeHQyTXd.../cancel",
                        "state": "active",
                        "signup_date": "2015-10-06 09:44:23",
                        "last_payment": {
                            "amount": 5,
                            "currency": "EUR",
                            "date": "2015-10-06"
                        },
                        "payment_information": {
                            "payment_method": "card",
                            "card_type": "visa",
                            "last_four_digits": "1111",
                            "expiry_date": "02/2020"
                        },
                        "quantity": 3,
                        "next_payment": {
                            "amount": 10,
                            "currency": "EUR",
                            "date": "2015-11-06"
                        }
                    },
                    paddleSubscriptionPlans: [
                        {
                            id: 3,
                            name: createFakeActivationCode(),
                            billing_type: "month",
                            billing_period: 1,
                            initial_price: {
                                "EUR": "0.00"
                            },
                            recurring_price: {
                                "EUR": "3.00"
                            },
                            trial_days: 30
                        },
                        {
                            id: 4,
                            name: createFakeActivationCode(),
                            billing_type: "year",
                            billing_period: 1,
                            initial_price: {
                                "EUR": "0.00"
                            },
                            recurring_price: {
                                "EUR": "24.00"
                            },
                            trial_days: 30
                        }
                    ]
                });
            } else {
                return (await createAPIRequest<DetailedSubscription2>(`detailedsubscription2/${subscription2Id}`, getAccessTokenSilently)).body!;
            }
        },


        patchSubscription: (subscription2Id: string, body: { [index: string]: any }) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`subscription2/${subscription2Id}`, getAccessTokenSilently, "PATCH", undefined, body);
            }
        },


        cancelSubscription: (subscription2Id: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`subscription2/${subscription2Id}/cancel`, getAccessTokenSilently, "POST", undefined, {});
            }
        },

        updateDelegatedAdmin: (subscription2id: string, displayName: string, alternativeAdminEmail: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`subscription2/${subscription2id}`, getAccessTokenSilently, "PATCH", undefined, { displayName: displayName, alternativeAdminEmail: alternativeAdminEmail });
            }
        },


        getSubscription2s: (pageIndex: number, pageSize: number, searchString?: string, currentUserOnly?: boolean, userId?: string, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<Subscription2>(Array.from(Array(pageSize).keys()).map((i) => {
                    const hasSubscription = Math.floor(Math.random() * 2) > 0;
                    return {
                        id: createGUID(),
                        userId: createGUID(),
                        creationDate: "2022-09-11",
                        notes: "",
                        currency: "EUR",
                        price: 20.0,
                        cancelURL: "https://epicpen.com",
                        updateURL: "https://epicpen.com",
                        paddleCheckoutId: createFakeActivationCode(),
                        paddleSubscriptionId: createFakeActivationCode(),
                        paddleSubscriptionPlanId: createFakeActivationCode(),
                        numOfLicences: Math.floor(Math.random() * 30),
                        billingPeriod: i % 3 === 0 ? "monthly" : "yearly",
                        nextBillDate: "2022-09-1",
                        nextPaymentAmount: 24,
                        testMode: true,
                        status: i % 5 === 0 ? "active" : (i % 5 === 1 ? "trialing" : (i % 5 === 2 ? "pastdue" : (i % 5 === 3 ? "paused" : "deleted"))),
                        permanentModeStatus: i % 3 === 0 ? "refunded" : (i % 3 === 1 ? "active" : "refunded"),
                        activationCode: createFakeActivationCode(),
                        deviceIdCount: Math.floor(Math.random() * 30),
                        displayName: "Fake display name",
                        permanentModeOrderId: null,
                        activationCodeEnabled: true,
                        deleted: false,
                        mode: i % 2 === 0 ? "standard" : "permanent",
                        referralCode: null,
                        isPermenentModeTrialActivationCode: false,
                        permanentModeTrialLengthInDays: 0,
                        firstActivation: null,
                        alternativeAdminEmail: null,
                        user: createFakeUser(),
                        activationCodeEmailSentSuccessfully: false,
                        activationCodeEmailSendGridId: null,
                        cancelationScheduledDate: null
                    }
                }
                ), abortController);
            } else {
                return createPaginatedAPIRequest<Subscription2>(`subscription2${currentUserOnly ? "/ofcurrentuser" : ""}`, getAccessTokenSilently, undefined, { userId }, undefined, pageIndex, pageSize, searchString, abortController);
            }
        },


        getSubscription2: async (subscription2id: string) => {
            if (useFakeData) {
                return await createFakeDataResponse<Subscription2>({
                    id: createGUID(),
                    userId: createGUID(),
                    creationDate: "2022-09-11",
                    notes: "",
                    currency: "EUR",
                    price: 20.0,
                    cancelURL: "https://epicpen.com",
                    updateURL: "https://epicpen.com",
                    paddleCheckoutId: createFakeActivationCode(),
                    paddleSubscriptionId: createFakeActivationCode(),
                    paddleSubscriptionPlanId: createFakeActivationCode(),
                    numOfLicences: Math.floor(Math.random() * 30),
                    billingPeriod: "monthly",
                    nextBillDate: "2023-03-04T00:00:00",
                    nextPaymentAmount: 24,
                    testMode: true,
                    status: "active",
                    permanentModeStatus: null,
                    activationCode: createFakeActivationCode(),
                    deviceIdCount: Math.floor(Math.random() * 30),
                    displayName: "Fake display name",
                    permanentModeOrderId: null,
                    activationCodeEnabled: true,
                    deleted: false,
                    mode: "standard",
                    referralCode: null,
                    isPermenentModeTrialActivationCode: false,
                    permanentModeTrialLengthInDays: 0,
                    firstActivation: null,
                    alternativeAdminEmail: null,
                    user: createFakeUser(),
                    activationCodeEmailSentSuccessfully: false,
                    activationCodeEmailSendGridId: null,
                    cancelationScheduledDate: null
                });
            } else {
                return (await createAPIRequest<Subscription2>(`subscription2/${subscription2id}`, getAccessTokenSilently)).body!;
            }
        },


        putSubscription2: async (subscription2: Subscription2Upload) => {
            if (useFakeData) {
                return await createFakeDataRequest<undefined>(undefined);
            } else {
                return (await createAPIRequest<undefined>(`subscription2`, getAccessTokenSilently, "PUT", undefined, subscription2));
            }
        },


        createLicenseFile: async (subscription2id: string, notes : string) => {
            if (useFakeData) {
                return await createFakeDataRequest({ body: "", signature: "" });
            } else {
                return (await createAPIRequest<{body : string, signature : string}>(`subscription2/createLicenseFile`, getAccessTokenSilently, "POST", { subscription2id }, { notes }));
            }
        },

        //
        //   Device ids
        //
        deleteDeviceId: (deviceId: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`deviceId/${deviceId}`, getAccessTokenSilently, "DELETE");
            }
        },


        deleteDeviceIdsByActivationCode: (activationCode: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`deviceId`, getAccessTokenSilently, "DELETE", { activationCode });
            }
        },
        getDeviceIdsOfActivationCode: (activationCode: string, pageIndex: number, pageSize: number, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<DeviceId>(Array.from(Array(20).keys()).map((i) => {
                    return {
                        id: createGUID(),
                        activationCode: createFakeActivationCode(),
                        osType: i % 2 === 0 ? "mac" : "win",
                        activationDate: "",
                        activatedByGitSHA: "",
                        createdByEpicPenVersion: "",
                        lastVerifiedByEpicPenVersion: "",
                        signedHash: "",
                        creationDate: "",
                        createdByGitSHA: "",
                        lastVerificationDate: "",
                        lastVerifiedByGitSHA: "",
                        isTrialMode: "",
                        friendlyName: `Brian's MacBook Pro - ${i}`
                    }
                }
                ), abortController);
            } else {
                return createPaginatedAPIRequest<DeviceId>(`deviceId`, getAccessTokenSilently, undefined, { activationCode }, undefined, pageIndex, pageSize, undefined, abortController);
            }
        },


        //
        //   Custom orders
        //
        getCustomOrders: (pageIndex: number, pageSize: number, searchString?: string, userId?: string, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<CustomOrder>(Array.from(Array(pageSize).keys()).map((i) => createFakeCustomerOrder()), abortController);
            } else {
                return createPaginatedAPIRequest<CustomOrder>(`customorder`, getAccessTokenSilently, undefined, { userId }, undefined, pageIndex, pageSize, searchString, abortController);
            }
        },

        getCustomOrder: async (customOrderId: string) => {
            if (useFakeData) {
                return await createFakeDataResponse<CustomOrder>(createFakeCustomerOrder());
            } else {
                return (await createAPIRequest<CustomOrder>(`customorder/${customOrderId}`, getAccessTokenSilently)).body!;
            }
        },

        putCustomOrder: async (customOrderBody: { [index: string]: any }) => {
            if (useFakeData) {
                return await createFakeDataRequest<undefined>(undefined);
            } else {
                return (await createAPIRequest<undefined>(`customorder`, getAccessTokenSilently, "PUT", undefined, customOrderBody));
            }
        },

        patchCustomOrder: (customOrderId: string, body: { [index: string]: any }) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`customorder/${customOrderId}`, getAccessTokenSilently, "PATCH", undefined, body);
            }
        },

        deleteCustomOrder: (customOrderId: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`customorder/${customOrderId}`, getAccessTokenSilently, "DELETE");
            }
        },
        //
        // Misc
        //
        getLicenceDetailsOfCurrentUser: async () => {
            if (useFakeData) {
                var totalDeviceLicences = Math.floor(random() * 6 + 5)
                return await createFakeDataResponse({
                    totalDeviceLicences: totalDeviceLicences,
                    activatedDevices: totalDeviceLicences - Math.floor(random() * 3 + 1)
                }
                );
            } else {
                return (await createAPIRequest<LicenceDetails>("licencedetails/ofcurrentuser", getAccessTokenSilently)).body!;
            }
        },
        
        getCurrencies: async () => {
            if (useFakeData) {
                return await createFakeDataResponse<string[]>(["USD", "EUR", "GBP"]);
            } else {
                return (await createAPIRequest<string[]>(`currency`, getAccessTokenSilently)).body!;
            }
        },

    }
}

//@ts-ignore
const EpicPenContext = createContext<{ api: ReturnType<typeof createEpicPenLicencingAPI>, user: User, locale : string }>();


export {
    GetAccessTokenSilentlyOptions,
    EpicPenContext,


    createEpicPenLicencingAPI
}
