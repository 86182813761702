import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import generatedBuildInfo from '../../generatedBuildInfo.json';

const buildDate = new Date(generatedBuildInfo.buildDate);

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col md={6}>{buildDate.getFullYear()} © Minia.</Col>
                        <Col md={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Git commit:
                                <a href={`https://github.com/tank-studios/epic-pen-accounts/commit/${generatedBuildInfo.gitCommitHash}`} target="_blank" rel="noreferrer" className="ms-1 text-decoration-underline">
                                    {generatedBuildInfo.gitCommitHash.substring(0, 7)}
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
