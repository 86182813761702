import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem, Modal, Input, Alert, CardHeader, CardBody, CardFooter, CardGroup, Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { GetAccessTokenSilentlyOptions, BillingPeriod, DetailedSubscription2, PaddleBillingType, PaddleSubscriptionPlan, User } from "../epicPenHelpers/epicpen_licencing_api";
import { buildInfo } from "../epicPenHelpers/build_info";
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from "react-ga4";
import LoadingSpinner from "./LoadingSpinner";
import { countryCodeToName, moneyToDisplayString } from "../epicPenHelpers/epicPenUtils";




interface EPCardProps {
    titleIcon? : string
    title?: string
    actionButtons?: { buttons: { header: string, action: () => void }[], danger?: boolean }
    children: React.ReactNode
    primaryButton?: { header: string, action: () => void, loading: boolean, danger: boolean }
    danger?: boolean
}


const EPCard = (props: EPCardProps) => {
    return <Card className={`border ${props.danger ? "border-danger" : ""}`}>
        {props.titleIcon || props.title ? <CardHeader className={`align-items-center d-flex ${props.danger ? "border-danger" : ""}`}>
            <h5 className={`mb-0 flex-grow-1 ${props.danger ? "text-danger" : ""}`}>
                {props.titleIcon ? <i className={`mdi ${props.titleIcon} me-3`}></i> : []} {props.title}
            </h5>
            {
                props.actionButtons ? <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2 mb-0 my-n1">
                        <UncontrolledDropdown>
                            <DropdownToggle
                                type="button"
                                color="link"
                            >
                                <i className="mdi mdi-dots-horizontal"></i>
                            </DropdownToggle>
                            <DropdownMenu className={`dropdown-menu-end ${props.actionButtons.danger ? "dropdownmenu-danger" : ""}`}>{
                                props.actionButtons.buttons.map((button, i) =>
                                    <DropdownItem key={i} to="#" onClick={() => button.action()}>{button.header}</DropdownItem>
                                )
                            }</DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div> : []
            }
        </CardHeader> : []
        }
        <CardBody>
            {props.children}
        </CardBody>
        {props.primaryButton ? <CardFooter className={`${props.danger ? "border-danger" : ""}`}>
            <button
                type="button" className={`btn ${props.danger ? "btn-danger" : "btn-primary"} w-md float-end`} onClick={() => props.primaryButton?.action()}
            >
                {props.primaryButton.loading ? <React.Fragment><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}</React.Fragment> : ""}{props.primaryButton.header}
            </button>
        </CardFooter> :[] }
    </Card>
}
export default EPCard;
