import React, { useState, useEffect, useMemo, Fragment, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import {
    Badge,
    Col,
    Row,
    Table,
} from "reactstrap";
import { GetAccessTokenSilentlyOptions, PaginatedAPIResponse, Order, User, EpicPenContext } from "../../epicPenHelpers/epicpen_licencing_api";
import { buildInfo } from "../../epicPenHelpers/build_info";
import { countryCodeToName, createGUID, emailSendStatusToStatusBadge, moneyToDisplayString } from "../../epicPenHelpers/epicPenUtils";
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from "./../LoadingSpinner";
import { data } from "jquery";
import { TableContainer, LoadingState, PaginatedTable } from "./TableContainer";



interface UserTableProps {
    minimal?: boolean
}

const UserTable = (props: UserTableProps) => {
    const { api, locale } = useContext(EpicPenContext);
    return PaginatedTable<User>({
        minimal: props.minimal,
        fetchData: async (pageIndex, pageSize, searchString, abortController) => await api.getUsers(pageIndex, pageSize, searchString, abortController),
        columns: [
            {
                Header: "First name",
                accessor: "firstName",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    return cellProps.value;
                },
            },
            {
                Header: "Last name",
                accessor: "lastName",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    return cellProps.value;
                },
            },
            {
                Header: "Username",
                accessor: "username",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value;
                },
            },
            {
                Header: "Company",
                accessor: "company",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return cellProps.value;
                },
            },
            {
                Header: "Email",
                accessor: "email",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    return <React.Fragment><span>{cellProps.value} </span>{cellProps.row.original.emailVerified ? <Badge className="bg-success">Verified</Badge> : <Badge className="bg-danger">Unverfied</Badge>}</React.Fragment>;
                },
            },
            {
                Header: "Verification email",
                accessor: "emailVerificationEmailStatus",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    return emailSendStatusToStatusBadge(cellProps.value);
                },
            },
            {
                Header: "Country",
                accessor: "countryCode",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return countryCodeToName(cellProps.value);
                },
            },
            {
                Header: "Registration date",
                accessor: "registerDate",
                includeInMinimal: false,
                Cell: (cellProps: any) => {
                    return `${new Date(cellProps.value).toLocaleTimeString(locale)} ${new Date(cellProps.value).toLocaleDateString(locale)}`;
                },
            },
            {
                Header: "",
                accessor: "action2",
                includeInMinimal: true,
                Cell: (cellProps: any) => {
                    //console.log(JSON.stringify(cellProps));
                    return <Link to={`/profile/${cellProps.row.original.id}`}><button
                        type="button"
                        className="btn btn-light btn-sm float-end"
                    >
                        View profile
                    </button></Link>;
                },
            }
        ]
    })
}

export default UserTable;
