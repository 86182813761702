import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";
import { EpicPenContext, GetAccessTokenSilentlyOptions } from "../epicPenHelpers/epicpen_licencing_api";
import { buildInfo } from "../epicPenHelpers/build_info";
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from "react-ga4";


interface ResetActivationCodeButtonmProps {
    activationCode: string
    completed: () => void
}

const ResetActivationCodeButton = (props: ResetActivationCodeButtonmProps) => {
    const [pendingReset, setPendingReset] = useState<boolean>(false);
    const { user, api } = useContext(EpicPenContext);
    const resetActivationCodeClick = async () => {

        ReactGA.event("ep_reset_activation_code_clicked");
        setPendingReset(true);
        try {
            await api.deleteDeviceIdsByActivationCode(props.activationCode)
            props.completed()
        } catch (e) {
        }
        setPendingReset(false);
    };

    return <button
        type="button"
        className="btn btn-primary waves-effect waves-light w-100"
        onClick={resetActivationCodeClick}
    >
        {pendingReset ? <React.Fragment><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}Resetting activation code...</React.Fragment> : <React.Fragment>Reset activation code</React.Fragment>}
    </button>
}
export default ResetActivationCodeButton;
