import React, { useState, useEffect, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";


import { useAuth0 } from '@auth0/auth0-react';

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

//redux
import { useSelector } from "react-redux";
import { forEach } from "lodash";
import { buildInfo } from "../../../epicPenHelpers/build_info";
import { EpicPenContext } from "../../../epicPenHelpers/epicpen_licencing_api";

interface ActivationCode {
    id : string
}

const ProfileMenu = (props: any) => {
    
    const { user,
        isAuthenticated,
        isLoading,
        error,
        logout } = useAuth0();


    const epUser = useContext(EpicPenContext);

    const nickname = user?.nickname ?? "no user";

    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState<boolean>(false);

        return (
            <React.Fragment>
                <Dropdown
                    isOpen={menu}
                    toggle={() => setMenu(!menu)}
                    className="d-inline-block"
                ><DropdownToggle
                    className="btn header-item bg-soft-light border-start border-end"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                        <img
                            className="rounded-circle header-profile-user"
                            src={user?.picture ?? user1}
                            alt="Header Avatar"
                        />
                        <span className="d-none d-xl-inline-block ms-2 me-1">{nickname === "brian.hoary" ? "John Smith" : nickname}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        {
                            true ?
                                <React.Fragment>
                                    <Link to="/profile" >
                                    <DropdownItem >
                                        <i className="bx bx-wrench font-size-16 align-middle me-1" />
                                        Profile
                                    </DropdownItem>
                                        <div className="dropdown-divider" />
                                    </Link>
                                </React.Fragment> : []
                        }
                        <Link to="/#" onClick={() => logout()} className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                            <span>{props.t("Logout")}</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
};

ProfileMenu.propTypes = {
    success: PropTypes.any,
    t: PropTypes.any,
};
export default withTranslation()(ProfileMenu);
