import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import {
    Col,
    Row,
    Table,
} from "reactstrap";
import { GetAccessTokenSilentlyOptions, EpicPenContext, Order, DeviceId } from "../../epicPenHelpers/epicpen_licencing_api";
import { buildInfo } from "../../epicPenHelpers/build_info";
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from "./../LoadingSpinner";
import ReactGA from "react-ga4";
import { PaginatedTable } from "./TableContainer";

export interface DeviceIdDisplay {
    id: string
    friendlyName: string
    osType: string
}

interface DeactiveButtonProps {
    deactivateComplete: () => void
    row: DeviceIdDisplay
}

const DeactiveButton = (props: DeactiveButtonProps) => {
    const { api } = useContext(EpicPenContext);
    const [pendingDelete, setPendingDelete] = useState<boolean>(false);

    const handleClick = async () => {
        ReactGA.event("ep_deactivate_device_clicked");
        setPendingDelete(true);
        try {
            
            await api.deleteDeviceId(props.row.id)
            //setDeviceIds((deviceIds) => deviceIds.filter((deviceId) => deviceId.id !== cellProps.row.id));
            console.log("props.deactivateComplete(); called")
            props.deactivateComplete();
        } catch (e) {
        }
        setPendingDelete(false);
    };

    return <button
        type="button"
        className="btn btn-light btn-sm"
        onClick={!pendingDelete ? handleClick : (() => { })}
    >
        {pendingDelete ? "Deactivating..." : "Deactivate device"}
    </button>;
}

interface DeviceIdTableProps {
    activationCode: string
}

const DeviceIdTable = (props: DeviceIdTableProps) => {
    const { api, locale } = useContext(EpicPenContext);
    return PaginatedTable<DeviceId>({
        minimal: true,
        fetchData: async (pageIndex, pageSize, searchString, abortController) => await api.getDeviceIdsOfActivationCode(props.activationCode, pageIndex, pageSize, abortController),
        columns: (setRows) => {
            return [
                {
                    Header: "Device Name",
                    accessor: "friendlyName",
                    includeInMinimal: true,
                    Cell: (cellProps: any) => {
                        return cellProps.value === "TANK-PC-GALWAY" ? "OFFICE-PC" : cellProps.value;
                    },
                },
                {
                    Header: "Device OS",
                    accessor: "osType",
                    includeInMinimal: true,
                    Cell: (cellProps: any) => {
                        return cellProps.value;
                    },
                },
                {
                    Header: "",
                    accessor: "isTrialMode",
                    includeInMinimal: true,
                    Cell: (cellProps: any) => {
                        return <DeactiveButton row={cellProps.row.original} deactivateComplete={() => setRows((rows) => rows.filter((dId) => dId.id !== cellProps.row.original.id))} />
                    },
                },
            ]
        }
    })
}

export default DeviceIdTable;
